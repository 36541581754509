import React from "react";
function AdminUsersPageHeader() {
  return (
    <div className="Toolbar_toolbar__MNKTm">
  <h2>Role Management</h2>
  <div className="Toolbar_toolbarGroups__LDh29">
  <div className="Toolbar_toolbarGroups__tlq+H">
  <div className="FilterToolbar_filterToolbar__M0+T1">
  </div>
  {/* <button type="button" className="bp4-button narrow bold">
    <span
      aria-hidden="true"
      // icon="plus"
      className="bp4-icon bp4-icon-plus"
    ><i class="ti ti-plus"></i></span>
    <span className="bp4-button-text">Add Lead</span>
  </button> */}
  <div >
    <a href="/add-roles" className="add-lead-btn"> <i class="ti ti-plus"></i>Add Admin User Roles</a>
  </div>
</div>
  </div>
</div>
  );
}

export default AdminUsersPageHeader;
