import React, { useState } from "react";
import Page from "../../../layouts/Page/Page";
import Sidebar from "../../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import OrderCommissionsHeader from "./OrderCommissionsHeader";
// import UserList from "../../pages/Users/UserList";
import OrderCommissionRerportList from "../OrderCommissions/OrderCommissionRerportList";
import BreadCrumb from "../../../layouts/BreadCrumb/BreadCrumb";

function OrderCommissions() {
  const navigate = useNavigate();
  const [sidebarType, setSidebarType] = useState('full');
  const onNavBarClick = () =>{
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }

  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
    <Sidebar onNavBarClick = {onNavBarClick} />
    <Page title="" onNavBarClick = {onNavBarClick}  flex>
      <BreadCrumb
          title="Commission Detail Report"
          // image={dashboardbreadcrumimage}
          breadCrumbDetail={[
            { name: "Dashboard", link: "/dashboard" },
            { name: "Orders", link: null },
            { name: "Commission Report", link: null}
          ]}
        />
      {/* <OrderCommissionsHeader/> */}
      <OrderCommissionRerportList/>
    </Page>
    </div>
  );
}

export default OrderCommissions;
