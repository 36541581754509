import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import "./ExpendAssignUserPackage.css";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
} from "react-table";
import { Link } from "react-router-dom";
import * as helper from "vs-admin-panel/helper";

const ExpendAssignUserPackage = ({setUserDataAccount}) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");

  const [uploadActive, setUploadActive] = useState(false);
  const [uploadActiveError, setUploadActiveError] = useState("");

  const [importShopify, setImportShopify] = useState(false);
  const [importShopifyError, setImportShopifyError] = useState("");

  const [importWooCommerce, setImportWooCommerce] = useState(false);
  const [importWooCommerceError, setImportWooCommerceError] = useState("");

  const [uploadUnlimitedVideos, setUploadUnlimitedVideos] = useState(false);
  const [uploadUnlimitedVideosError, setUploadUnlimitedVideosError] =
    useState("");

  const [recordVideo, setRecordVideo] = useState(false);
  const [recordVideoError, setRecordVideoError] = useState("");

  const [liveStream, setLiveStream] = useState(false);
  const [liveStreamError, setLiveStreamError] = useState("");

  const [maxVideoLength, setMaxVideoLength] = useState("");
  const [maxVideoLengthError, setMaxVideoLengthError] = useState("");

  const [totalWatchTime, setTotalWatchTime] = useState("");
  const [totalWatchTimeError, setTotalWatchTimeError] = useState("");

  const [liveStreamWatchTime, setLiveStreamWatchTime] = useState("");
  const [liveStreamWatchTimeError, setLiveStreamWatchTimeError] = useState("");

  const [productsUnderOneVideo, setProductsUnderOneVideo] = useState("");
  const [productsUnderOneVideoError, setProductsUnderOneVideoError] =
    useState("");

  const [isUnlimitedPackage, setIsUnlimitedPackage] = useState(false);

  const [videoQuality, setVideoQuality] = useState("");
  const [videoQualityError, setVideoQualityError] = useState("");

  const [assignPackageLoader, setAssignPackageLoader] = useState(false);

  const [assignedPackageId, setAssignedPackageId] = useState(false);

  const [userId, setUserId] = useState(false);
  const [subscribedPlanId, setSubscribedPlanId] = useState(null);

  

  const [userCurrentPackage, setUserCurrentPackage] = useState({});
  const [userAccount, setUserAccount] = useState({});
  const [userCurrentPackageLoader, setUserCurrentPackageLoader] =
    useState(false);

  const videoQualityOptions = [
    { value: "720", label: "HD" },
    { value: "1080", label: "Full HD" },
  ];

  useEffect(() => {
    // console.log("helper.urlSegment(3)", );

    // setUserId(helper.urlSegment(2));

    // helper.urlSegment(3);
    getUserPackage(helper.urlSegment(2));
  }, []);

  //userId

  // useEffect(() => {
  //   getUserPackage();
  // }, [userId]);

  const assignPackageValues = (packageData) => {
    setUploadActive(packageData?.product_limit);
    setImportShopify(packageData?.import_products_from_shopify);
    setImportWooCommerce(packageData?.import_products_from_woocommerce);
    setUploadUnlimitedVideos(packageData?.video_limit);
    setRecordVideo(packageData?.video_record_max_length);
    setLiveStream(packageData?.live_stream_from_external_minutes);
    setProductsUnderOneVideo(packageData?.product_limit_under_single_video);
    setMaxVideoLength(packageData?.video_length_limit);
    setTotalWatchTime(packageData?.watch_time);
    setIsUnlimitedPackage(packageData?.is_unlimited_package);
    setVideoQuality(packageData?.video_quality);
    // setLiveStreamWatchTime(packageData?.live_stream_time);
    setLiveStreamWatchTime(packageData?.live_stream_from_video_squirrel);
    setAssignedPackageId(packageData?.id);
  };

  const saveUpdatePackage = () => {
    setSuccess(null);
    setErrors(null);

    setAssignPackageLoader(true);
    const savePackageData = {
      userId: userId,
      uploadActive: uploadActive,
      importShopify: importShopify,
      importWooCommerce: importWooCommerce,
      uploadUnlimitedVideos: uploadUnlimitedVideos,
      recordVideo: recordVideo,
      liveStream: liveStream,
      maxVideoLength: maxVideoLength,
      totalWatchTime: totalWatchTime,
      liveStreamWatchTime: liveStreamWatchTime,
      productsUnderOneVideo: productsUnderOneVideo,
      isUnlimitedPackage: isUnlimitedPackage,
      videoQuality: videoQuality,
      assignedPackageId: assignedPackageId,
    };

    fetch(process.env.REACT_APP_API_URL + "/change-update-current-package", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.userLoggedAccessToken(),
      },
      // body: JSON.stringify({ userId: userId }), // Replace with your POST data
      body: JSON.stringify(savePackageData), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setSuccess(
            data?.message ? data?.message : "Package upgraded!"
          );
        } else {
          setErrors(
            data?.message ? data?.message : "Something went wrong!"
          );
        }
      })
      .catch((error) => {
        setErrors("Something went wrong!");
      })
      .finally((error) => {
        setAssignPackageLoader(false);
      });
  };

  const getUserPackage = (userIdParam) => {
    setUserCurrentPackageLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/admin/user-current-package", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.userLoggedAccessToken(),
      },
      body: JSON.stringify({ userId: userIdParam }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        //setUserCurrentPackage
        // const pckgValues = data?.data;
        setUserCurrentPackage(data?.data?.userSubscription);
        assignPackageValues(data?.data?.userSubscription);
        setUserAccount(data?.data?.account);
        setUserId(data?.data?.account?.id);

        setUserDataAccount(data?.data?.account);

        // subscribedPlanId, setSubscribedPlanId

        setSubscribedPlanId(data?.data?.userSubscription?.id);

        // console.log('!data?.data?.userSubscription?.id',!data?.data?.userSubscription?.id);
        if(!data?.data?.account){
          setErrors('User not found');
        } else if(!data?.data?.userSubscription?.id){
          setErrors('Currently '+data?.data?.account?.name+' don\'t have any active subscription');
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setUserCurrentPackageLoader(false);
      });
  };

  const isButtonDisabled =
    uploadActiveError ||
    importShopifyError ||
    importWooCommerceError ||
    uploadUnlimitedVideosError ||
    recordVideoError ||
    liveStreamError ||
    maxVideoLengthError ||
    totalWatchTimeError ||
    liveStreamError ||
    productsUnderOneVideoError;

  // const unlimitedPackageVol = process.env.REACT_APP_UNLIMITED_PACKAGE_VOLUME_VALUE;
  const unlimitedPackageVol = 100000000;

  return (
    <>
      <div className="card card-body App">
      {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error:</strong> {(errors)} </div> : ""}
      {(success) ? <div class="alert alert-success" role="alert"><strong>Success:</strong> {(success)} </div> : ""}

        {userCurrentPackageLoader === true && (
          <div class="d-flex justify-content-center m-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {/* {subscribedPlanId} */}
        {userCurrentPackageLoader !== true && subscribedPlanId>=1 && (
          <div className="assign-package-to-user">
            {/* <button
              className="close-toggle-btn"
              onClick={(e) => {
                props.closeFromChild();
              }}
              type="button"
            >
              <i className="ti ti-x fs-10"></i>
            </button> */}

            <div className="col-xs-12">
              <div className="d-flex align-items-center gap-2 mb-4"></div>

              <div className="m-5">
                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">
                  <h5>{userCurrentPackage?.plan_name}</h5>
                  <div className="clearfix">
                    {/* Total Watch Time */}
                    

                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <div className="form-check d-flex align-items-center gap-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              checked={isUnlimitedPackage}
                              name="inlineRadioOptions"
                              id="assign-unlimited-package"
                              defaultValue="option1"
                              onChange={(e) => {
                                setIsUnlimitedPackage(true);
                              }}
                              style={{ width: 20, height: 20 }}
                            />
                            <label
                              className="form-check-label mt-1"
                              htmlFor="assign-unlimited-package"
                            >
                              Unlimited Account
                            </label>
                          </div>

                          <div className="form-check d-flex align-items-center gap-2 mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              checked={isUnlimitedPackage == false}
                              name="inlineRadioOptions"
                              id="assign-standard-package"
                              defaultValue="option1"
                              onChange={(e) => {
                                setIsUnlimitedPackage(false);
                              }}
                              style={{ width: 20, height: 20 }}
                            />
                            <label
                              className="form-check-label mt-1"
                              htmlFor="assign-standard-package"
                            >
                              Extended Account Package
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="totalWatchTime">
                            Total Watch Time Minutes:<span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              totalWatchTime == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              totalWatchTime == unlimitedPackageVol
                                ? "UNLIMITED"
                                : totalWatchTime
                            }
                            id="totalWatchTime"
                            className="form-control"
                            onChange={(e) => {
                              setTotalWatchTime(e.target.value);
                              setTotalWatchTimeError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Total Watch Time cannot be empty"
                                  : ""
                              );
                            }}
                          />
                          {totalWatchTimeError &&
                            isUnlimitedPackage !== true && (
                              <small className="text-danger">
                                {totalWatchTimeError}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>


                     {/* Maximum Video length/time */}
                     <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="maxVideoLength">
                            Video Time Minutes:
                            <span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              maxVideoLength == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              maxVideoLength == unlimitedPackageVol
                                ? "UNLIMITED"
                                : maxVideoLength
                            }
                            id="maxVideoLength"
                            className="form-control"
                            onChange={(e) => {
                              setMaxVideoLength(e.target.value);
                              setMaxVideoLengthError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Maximum Video length/time cannot be empty"
                                  : ""
                              );
                            }}
                          />
                          {maxVideoLengthError &&
                            isUnlimitedPackage !== true && (
                              <small className="text-danger">
                                {maxVideoLengthError}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* Upload Active */}
                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="uploadActive">
                            Upload Product Limits:
                            <span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              uploadActive == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            id="uploadActive"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              uploadActive == unlimitedPackageVol
                                ? "UNLIMITED"
                                : uploadActive
                            }
                            className="form-control"
                            onChange={(e) => {
                              setUploadActive(e?.target?.value);
                              setUploadActiveError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Upload Product Limits cannot be empty"
                                  : ""
                              );
                            }}
                          />

                          {uploadActiveError && isUnlimitedPackage !== true && (
                            <small className="text-danger">
                              {uploadActiveError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="mb-3 row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="importShopify">
                          Import products from Shopify:
                          <span className="danger">*</span>
                        </label>
                        <input
                          type={(isUnlimitedPackage === true || uploadActive == unlimitedPackageVol) ? "text" : "number"}
                          pattern="[0-9]"
                          id="importShopify"
                          disabled={(isUnlimitedPackage === true)}
                          value={
                            (isUnlimitedPackage === true || uploadActive == unlimitedPackageVol)
                              ? "UNLIMITED"
                              : importShopify
                          }
                          className="form-control"
                          onChange={(e) => {
                            setImportShopify(e?.target?.value);
                            setImportShopifyError(
                              e?.target?.value?.trim()?.length <= 0
                                ? "Import products from Shopify cannot be empty"
                                : ""
                            );
                          }}
                        />
                      </div>

                      {importShopifyError && isUnlimitedPackage !== true && (
                        <small className="text-danger">
                          {importShopifyError}
                        </small>
                      )}
                    </div>
                  </div> */}

                    {/* Import products from WooCommerce */}
                    {/* <div className="mb-3 row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="importWooCommerce">
                          Import products from WooCommerce:
                          <span className="danger">*</span>
                        </label>
                        <input
                          type={(isUnlimitedPackage === true || uploadActive == unlimitedPackageVol) ? "text" : "number"}
                          pattern="[0-9]"
                          id="importWooCommerce"
                          disabled={(isUnlimitedPackage === true)}
                          value={
                            (isUnlimitedPackage === true || uploadActive == unlimitedPackageVol)
                              ? "UNLIMITED"
                              : importWooCommerce
                          }
                          className="form-control"
                          onChange={(e) => {
                            setImportWooCommerce(e?.target?.value);
                            setImportWooCommerceError(
                              e?.target?.value?.trim()?.length <= 0
                                ? "Import products from WooCommerce cannot be empty"
                                : ""
                            );
                          }}
                        />
                        {importWooCommerceError &&
                          isUnlimitedPackage !== true && (
                            <small className="text-danger">
                              {importWooCommerceError}
                            </small>
                          )}
                      </div>
                    </div>
                  </div> */}

                    {/* Upload Unlimited Videos */}
                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="uploadUnlimitedVideos">
                            Upload Videos:
                            <span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              uploadUnlimitedVideos == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            id="uploadUnlimitedVideos"
                            className="form-control"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              uploadUnlimitedVideos == unlimitedPackageVol
                                ? "UNLIMITED"
                                : uploadUnlimitedVideos
                            }
                            onChange={(e) => {
                              setUploadUnlimitedVideos(e?.target?.value);
                              setUploadUnlimitedVideosError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Upload Unlimited Videos cannot be empty"
                                  : ""
                              );
                            }}
                          />
                          {uploadUnlimitedVideosError &&
                            isUnlimitedPackage !== true && (
                              <small className="text-danger">
                                {uploadUnlimitedVideosError}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* Record Video */}
                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="recordVideo">
                            Record Video Minutes:
                            <span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              recordVideo == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              recordVideo == unlimitedPackageVol
                                ? "UNLIMITED"
                                : recordVideo
                            }
                            id="recordVideo"
                            className="form-control"
                            onChange={(e) => {
                              setRecordVideo(e?.target?.value);
                              setRecordVideoError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Record Video Minutes cannot be empty"
                                  : ""
                              );
                            }}
                          />
                          {recordVideoError && isUnlimitedPackage !== true && (
                            <small className="text-danger">
                              {recordVideoError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Live Stream from External Source */}
                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="liveStream">
                            Live Stream from External Source:
                            <span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              liveStream == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            id="liveStream"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              liveStream == unlimitedPackageVol
                                ? "UNLIMITED"
                                : liveStream
                            }
                            className="form-control"
                            onChange={(e) => {
                              setLiveStream(e?.target?.value);
                              setLiveStreamError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Live Stream from External Source cannot be empty"
                                  : ""
                              );
                            }}
                          />
                          {liveStreamError && isUnlimitedPackage !== true && (
                            <small className="text-danger">
                              {liveStreamError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Live Stream watch time */}
                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="liveStreamWatchTime">
                            Live Stream Minutes:
                            <span className="danger">*</span>
                          </label>
                          <input
                            type={
                              isUnlimitedPackage === true ||
                              liveStreamWatchTime == unlimitedPackageVol
                                ? "text"
                                : "number"
                            }
                            pattern="[0-9]"
                            id="liveStreamWatchTime"
                            className="form-control"
                            disabled={isUnlimitedPackage === true}
                            value={
                              isUnlimitedPackage === true ||
                              liveStreamWatchTime == unlimitedPackageVol
                                ? "UNLIMITED"
                                : liveStreamWatchTime
                            }
                            onChange={(e) => {
                              setLiveStreamWatchTime(e.target.value);
                              setLiveStreamWatchTimeError(
                                e?.target?.value?.trim()?.length <= 0
                                  ? "Live Stream cannot be empty"
                                  : ""
                              );
                            }}
                          />
                          {liveStreamWatchTimeError &&
                            isUnlimitedPackage !== true && (
                              <small className="text-danger">
                                {liveStreamWatchTimeError}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="mb-3 row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="productsUnderOneVideo">
                          Products Under one Video:
                          <span className="danger">*</span>
                        </label>
                        <input
                          type={(isUnlimitedPackage === true || productsUnderOneVideo == unlimitedPackageVol) ? "text" : "number"}
                          pattern="[0-9]"
                          id="productsUnderOneVideo"
                          disabled={(isUnlimitedPackage === true)}
                          value={
                            (isUnlimitedPackage === true || productsUnderOneVideo == unlimitedPackageVol)
                              ? "UNLIMITED"
                              : productsUnderOneVideo
                          }
                          className="form-control"
                          onChange={(e) => {
                            setProductsUnderOneVideo(e.target.value);
                            setProductsUnderOneVideoError(
                              e?.target?.value?.trim()?.length <= 0
                                ? "Products Under one Video cannot be empty"
                                : ""
                            );
                          }}
                        />
                        {productsUnderOneVideoError &&
                          isUnlimitedPackage !== true && (
                            <small className="text-danger">
                              {productsUnderOneVideoError}
                            </small>
                          )}
                      </div>
                    </div>
                  </div> */}

                    {/* Video Quality */}
                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="videoQuality">
                            Video Quality:<span className="danger">*</span>
                          </label>
                          <select
                            id="videoQuality"
                            className="form-control"
                            value={videoQuality}
                            onChange={(e) => {
                              setVideoQuality(e.target.value);
                              setVideoQualityError("");
                            }}
                          >
                            {videoQualityOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {videoQualityError && isUnlimitedPackage !== true && (
                            <small className="text-danger">
                              {videoQualityError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12">
                        <div className="mb-3">
                          <div
                            className="actions clearfix"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <ul
                              role="menu"
                              aria-label="Pagination"
                              style={{ display: "flex" }}
                            >
                              {/* <li
                                className="mr-10"
                                aria-hidden="false"
                                aria-disabled="false"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary text-white"
                                  onClick={(e) => {
                                    props.closeFromChild();
                                  }}
                                >
                                  Cancel{" "}
                                </button>
                              </li> */}

                              {/* <li className="disabled" aria-disabled="true"><a href="#previous" role="menuitem">Previous</a></li> */}
                              <li aria-hidden="false" aria-disabled="false">
                                <button
                                  type="button"
                                  onClick={saveUpdatePackage}
                                  disabled={
                                    isButtonDisabled || assignPackageLoader
                                  }
                                  class="btn btn-primary text-white"
                                >
                                  <div class="d-flex align-items-center">
                                    {assignPackageLoader ? (
                                      <div>
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </div>
                                    ) : (
                                      "Update Package"
                                    )}
                                  </div>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExpendAssignUserPackage;
