import React, { useEffect, useState } from "react";
import { Sidebar } from "../../layouts/Sidebar/Sidebar";
import { Page } from "../../layouts/Page/Page";
import { Link } from "react-router-dom";
import Dailywatchtime from "../../layouts/Graph/Watchtime/Watchtime";
import Dailycta from "../../layouts/Graph/Cta/Cta";
import dashboardbreadcrumimage from "../../../../src/assets/head-slider-image.png";

function StatsDashboard() {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [watchtime, setWatchtime] = useState(0);
    const [avgwatchtime, setAvgwatchtime] = useState(0);
    const [avgwatchthrough, setAvgwatchthrough] = useState(0);
    const [videoview, setVideoview] = useState(0);
    const [totalviewers, setTotalviewers] = useState(0);
    const [videocompletions, setVideocompletions] = useState(0);
    const [activeviews, setActiveviews] = useState(0);
    const [cta, setCta] = useState(0);
    const [ctathrough, setCtathrough] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        // var token = localStorage.getItem("authSession")
        // var token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/admin-videos-stats', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({}), // Replace with your POST data
            //body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data.data)
                setWatchtime(data.data.watch_time)
                setAvgwatchtime(data.data.avg_watch_time)
                setAvgwatchthrough(data.data.avg_watch_through)
                setVideoview(data.data.video_view)
                setTotalviewers(data.data.total_viewers)
                setVideocompletions(data.data.video_completions)
                setActiveviews(data.data.active_views)
                setCta(data.data.cta)
                setCtathrough(data.data.cta_clicks_through)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    return (
        <div className="container-fluid">   
            <div class="card bg-light-info shadow-none position-relative overflow-hidden">
                
                <div class="card-body px-4 py-3">
                   <div class="row align-items-center">
                    
                        <div class="col-9"><h4 class="fw-semibold mb-8">
                            Welcome to Video Squirrel</h4>
                            <p class="card-head-text">Welcome to the admin panel, where you have the power to manage and oversee every aspect of the system. Your administrative privileges grant you control over user management, content, settings, and more. Thank you for helping keep things running smoothly!</p>
                        </div>
                        <div class="col-3">
                            <div class="text-center mb-n5 dashboard-image">
                                <img src={dashboardbreadcrumimage} alt="" class="img-fluid mb-n4" />
                            </div></div></div></div></div>
             <div className="row">
             <div className="col-sm-6 col-xl-3">
                    <div className="card bg-baby-blue1 shadow-none">
                        <div className="card-body p-4">
                            <div className="align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary align-items-center justify-content-center">
                                    <i className="ti ti-users text-white fs-7" />
                                </div>
                                <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading1">{videoview} Viewers</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading1-1">Total # of Viewers </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card bg-misty-rose shadow-none">
                        <div className="card-body p-4">
                            <div className="align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary align-items-center justify-content-center">
                                    <i className="ti ti-clock-record text-white fs-7" />
                                </div>
                                <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading2">{avgwatchthrough}</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading2-1">Avg Watchthroughs</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card bg-platinum shadow-none">
                        <div className="card-body p-4">
                            <div className="align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary align-items-center justify-content-center">
                                    <i className="ti ti-clock-play text-white fs-7" />
                                </div>
                                <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading3">{watchtime}</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading3-1">Watch Time</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-xl-3">
                    <div className="card bg-floral-white shadow-none">
                        <div className="card-body p-4">
                            <div className="align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary align-items-center justify-content-center">
                                    <i className="ti ti-eye-check text-white fs-7" />
                                </div>
                           <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading4">{totalviewers} Views</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading4-1">Video Views</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">
            <div className="col-sm-6 col-xl-3">
                    <div className="card bg-melon shadow-none">
                        <div className="card-body p-4">
                            <div className="align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary align-items-center justify-content-center">
                                    <i className="ti ti-star-half-filled text-white fs-7" />
                                </div>
                            <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading5">{videocompletions}%</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading5-1">Completion Rate</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card bg-white-smoke shadow-none">
                        <div className="card-body p-4">
                            <div className=" align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary align-items-center justify-content-center">
                                    <i className="ti ti-clock-hour-10 text-white fs-7" />
                                </div>
                            <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading6">{avgwatchtime}</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading6-1">Avg Watch time </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-xl-3">
                    <div className="card bg-peach-puff shadow-none">
                        <div className="card-body p-4">
                            <div className=" align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary  align-items-center justify-content-center">
                                    <i className="ti ti-brand-google-play text-white fs-7" />
                                </div>
                                <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading7">{cta}Clicks</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading7-1">CTA Clicks</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-xl-3">
                    <div className="card bg-baby-blue shadow-none">
                        <div className="card-body p-4">
                            <div className=" align-items-center dashboard-align-center">
                                <div className="round rounded bg-primary  align-items-center justify-content-center">
                                    <i className="ti ti-hand-click text-white fs-7" />
                                </div>
                            <br/>
                                <h3 className="mb-0 fw-semibold fs-7 box-heading8">{ctathrough}</h3>
                                <h6 className="mb-0 ms-3 mt-2 box-heading8-1">Click Through</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-lg-6">
                    <Dailywatchtime />
                </div>
                <div class="col-lg-6">
                    <Dailycta />
                </div>
            </div>


        </div>

    );
}
export default StatsDashboard;