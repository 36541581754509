import React, { useEffect, useState } from 'react';
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import validator from 'validator';
import Select from 'react-select';

function AddAdminRoles() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState('');
    const [screen, setScreen] = useState('');
    const [screendata, setScreendata] = useState([]);
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {

        const userscreens = window.localStorage.getItem("admin_screens");
        const screensArray = userscreens.split(',');
        if (!screensArray.includes("admin-roles")) {
          navigate("/");
        }

        fetchVideosData();

    }, []);


    const fetchVideosData = () => {
        // var token = localStorage.getItem("authSession")
        // var token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/all-screens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
               // Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({}), // Replace with your POST data
            //body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setScreendata(data.data)
                setOptions(data.data)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const resetState = () => {
        setErrors('');
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validator.isEmpty(name)) {
            setErrors('Name is required.');
        }


        // if (validator.isEmpty(screen)) {
        //     setErrors('role is required.');
        // }


        if (errors == "") {

            // submitForm(name, screen);
            submitForm(name);

        }


        // submitForm(role,name,email,phone,datetime);

        setTimeout(resetState, 2000);

    };


    const handleVdeoChange = (e) => {
        e.preventDefault();
        setScreendata(e.target.value)
        //fetchVideosData(e.target.value);
    };

    const submitForm = (name) => {
        setloaderSubmit(true)
        // var token = localStorage.getItem("authSession")
        // var token = JSON.parse(token);

        const screenValues = selectedOption.map(screen => screen.value).join(',');
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-role', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "name": name,
                    "screen": screenValues,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.code == "200") {
                            setSuccess("Admin Added succesfully")
                            navigate("/admin-users")
                            setloaderSubmit(false)
                        } else {
                            navigate("/add-admin-users")
                            setloaderSubmit(false)
                        }
                    });
                }
            }).
                catch((error) => {
                    setloaderSubmit(false)
                    console.log('error>>>', error)
                });
        });
        return fetch_prods;
    };



    // const isButtonDisabled = !name || !screen;
    const isButtonDisabled = !name;


    return (
        <div class="page-wrapper" id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar />
            <Page title="Add Admin Users Roles" formWidth={true}>
                <div className="container-fluid">
                    <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                        <div className="card-body px-4 py-3">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <h4 className="fw-semibold mb-8">Add Admin User Roles</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link className="text-muted" to="/">
                                                    Dashboard
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                            Add Admin User Roles
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-3">
                                    <div className="text-center mb-n5">
                                        <img
                                            src="../../dist/images/breadcrumb/ChatBc.png"
                                            alt=""
                                            className="img-fluid mb-n4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="card">
                            <div className="card-body wizard-content">

                                {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}



                                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">

                                <div className="content clearfix">


                                   

                                 
                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Role Name:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Role Name'
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="name" value={name} onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label htmlFor="wfirstName2">Select Screen:<span className="danger">*</span></label>
                                                <Select
                                                isMulti
                                                menuPortalTarget={document.body} menuPosition={'fixed'}
                                                options={options}
                                                onChange={setSelectedOption}
                                                className="basic-multi-select"
                                                classNamePrefix="select screen"
                                            />
                                            </div>
                                        </div>
                                    </div>


                                    <div class="actions clearfix">
                                        <ul role="menu" aria-label="Pagination">
                                            <li aria-hidden="false" aria-disabled="false">
                                            <button type="submit" onClick={handleSubmit} disabled={isButtonDisabled} class="mr-5 btn btn-primary text-white float-end">
                                               
                                                    {loaderSubmit ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                        </div>
                                                    ) : (
                                                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                    )}
                                                
                                            </button>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </section>
                </div>

            </Page>
        </div>
    );
}
export default AddAdminRoles;