import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function Page({ formWidth ,title, children,onNavBarClick,userData}) {


  const userDatapicture = window.localStorage.getItem("admin_picture");
  const userDataname = window.localStorage.getItem("admin_name");
  const userDataemail = window.localStorage.getItem("admin_email");

  const navigate = useNavigate();

  const [isFixedHeader, setIsFixedHeader] = useState(false);
 
  useEffect(() => {

    const adminauthSession = localStorage.getItem('adminauthSession');
    

    if (!adminauthSession) {
      navigate("/");
    }
    
    const handleScroll = () => {
      if (window.scrollY >= 60) {
        setIsFixedHeader(true);
      } else {
        setIsFixedHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
};


  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="body-wrapper">
        <header className={isFixedHeader ? 'app-header fixed-header' : 'app-header'}>
          <nav className="navbar navbar-expand-lg navbar-light">
            <ul className="navbar-nav"  onClick={onNavBarClick}>
              <li className="nav-item">
                <a className="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse" href="javascript:void(0)">
                  <i className="ti ti-menu-2"></i>
                </a>
              </li>

            </ul>
            <div className="d-block d-lg-none">
              <img src="../../dist/images/logos/dark-logo.svg" className="dark-logo" width="180" alt="" />
              <img src="../../dist/images/logos/light-logo.svg" className="light-logo" width="180" alt="" />
            </div>
            <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="p-2">
                <i className="ti ti-dots fs-7"></i>
              </span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <div className="d-flex align-items-center justify-content-between">
                <a href="javascript:void(0)" className="nav-link d-flex d-lg-none align-items-center justify-content-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobilenavbar" aria-controls="offcanvasWithBothOptions">
                  <i className="ti ti-align-justified fs-7"></i>
                </a>
                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li className="nav-item">
      </li>


      <li className="nav-item dropdown user-account-nav">
                    <a
                      className="nav-link pe-0 show-first-link"
                      href="javascript:void(0)"
                      id="drop1"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <div className="d-flex align-items-center">
                        <div className="user-profile-img">
                          {/* {data?<img src={data.accounts[0].picture}  className="rounded-circle" width={35} height={35} alt=""/>:""} */}
                          <img src={userDatapicture} className="rounded-circle" width={35} height={35} alt=""/>
                        </div>
                      </div>
                    </a>
                    <div
                      className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up all-drop-menu-links"
                      aria-labelledby="drop1"
                      data-bs-popper="static"
                    >
                      <div className="profile-dropdown position-relative" data-simplebar="init">
                        <div className="simplebar-wrapper" style={{ margin: 0 }}>
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer" />
                          </div>
                          <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex={0}
                                role="region"
                                aria-label="scrollable content"
                                style={{ height: "auto", overflow: "hidden scroll" }}
                              >
                                <div className="simplebar-content" style={{ padding: 0 }}>
                                  <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                                  {/* {data?<img src={data.accounts[0].picture} className="rounded-circle"  width={80}  height={80}  alt=""/>:""} */}
                                  <img src={userDatapicture} className="rounded-circle"  width={80}  height={80}  alt=""/>
                                    <div className="ms-3">
                                      <h5 className="mb-1 fs-3"> {userDataname}</h5>
                                      <p className="mb-0 d-flex text-dark align-items-center gap-2">
                                        <i className="ti ti-mail fs-4" /> {userDataemail}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className="message-body">

                                    <Link
                                      to="/settings/profile"
                                      className="py-8 px-7 mt-8 d-flex align-items-center"
                                    >
                                      <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                                      <i class="ti ti-settings"></i>
                                      </span>
                                      <div className="w-75 d-inline-block v-middle ps-3">
                                        <h6 className="mb-1 bg-hover-primary fw-semibold">
                                          {" "}
                                          My Profile{" "}
                                        </h6>
                                      </div>
                                    </Link>
                                  </div> */}
                                  <div className="d-grid py-4 px-7 pt-8">
                                    {/* <div className="upgrade-plan bg-light-primary position-relative overflow-hidden rounded-4 p-4 mb-9">
                                      <div className="row">
                                        <div className="col-6">
                                          <h5 className="fs-4 mb-3 w-50 fw-semibold text-dark">
                                            Unlimited Access
                                          </h5>
                                        </div>
                                        <div className="col-6">
                                          <div className="m-n4">
                                            <img
                                              src="../../dist/images/backgrounds/unlimited-bg.png"
                                              alt=""
                                              className="w-100"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}

                                    <button type="button"   onClick={handleSubmit}  className="btn btn-outline-primary">
                                      Log Out
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="simplebar-placeholder"
                            style={{ width: "auto", height: 400 }}
                          />
                        </div>
                        <div
                          className="simplebar-track simplebar-horizontal"
                          style={{ visibility: "hidden" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{ width: 0, display: "none" }}
                          />
                        </div>
                        <div
                          className="simplebar-track simplebar-vertical"
                          style={{ visibility: "visible" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              height: 536,
                              display: "block",
                              transform: "translate3d(0px, 0px, 0px)"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </li>



                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className={formWidth ? "container-fluid form-width": "container-fluid"}>
          {children}
        </div>
      </div>
    </>
  );
}

export default Page;
