export const urlSegment = (segmentNumber) => {
  try {
    const segmentArray = (window.location.pathname).split('/').filter(item => item);
    if (!isNaN(segmentNumber) && segmentArray[segmentNumber]) {
      return segmentArray[segmentNumber];
    }
  } catch (error) {
    // Handle any errors that may occur
    console.error('An error occurred:', error);
  }
  return null;
};

export const userLoggedAccessToken = () => {
  try{
    const accessToken = JSON.parse(window.localStorage.getItem("adminauthSession")).accessToken;
    return accessToken;
  } catch(error){
    //@TODO: logout user and redirect to login page
    
    // window.location.href = "/";
    // history.push({
    //   pathname: '/',
    // });
  }
}

export const showWidgetSharingCode = (widgetData) => {
  return `<script>window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${widgetData.app_id}');</script>`;
}

export const videoWatchUrl= (videoId) => {
  return process.env.REACT_APP_WEB_URL+'/watch/'+videoId;
}

export const extractNumberFromUserId = (userRawId) => {
  return userRawId?.split('|')[1];
}

export const removeTrailingZeros = (number) => {

  const numberString = parseFloat(number)?.toString();
  const trimmedNumberString = numberString?.replace(/(\.\d*?[1-9])0*$/g, '$1');
  return number>=0?parseFloat(trimmedNumberString):number;
}

export const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const truncateText = (text, maxLength = 35) => {
  if (!text || typeof text === "undefined") {
    return "";
  }
  if (text?.length > maxLength) {
    return text?.slice(0, maxLength) + " ...";
  }
  return text;
}


export const htmlToPlainText = (html) => {
  if (!html || typeof html === "undefined") {
    return "";
  }

  try {
    return html.replace(/<[^>]+>/g, "");
  } catch (errorMessage) {
    return "";
  }
}

export const isMobile = () => {
  return window?.matchMedia('(max-width: 768px)')?.matches;
}
