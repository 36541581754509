import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function SidebarItem({ isOnBoardingComplete, active, icon, label, collapsed, onClick, childMenu,isSubMenu }) {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  function handleClickEvent(){
    onClick();
  }
  function openCloseSubMenu(){
    setOpenSubMenu(openSubMenu ? false:true);
  }



  return(  
    <li className={isOnBoardingComplete ? "sidebar-item": "sidebar-item disabled-event" }>

      <a className={(isSubMenu && !active) ? "sidebar-link has-arrow" :(isSubMenu && active) ? "sidebar-link has-arrow active" :active ? "sidebar-link active":"sidebar-link"} onClick={isSubMenu?openCloseSubMenu:handleClickEvent} aria-expanded="false">

        <span>
          <i className={icon}></i>
        </span>
        <span className="hide-menu" >{label}</span>
      </a>
      {typeof childMenu!=='undefined' && childMenu.length>=1?
        <ul className={(openSubMenu) ? "collapse first-level in":"collapse first-level"}>
        {childMenu.map(({name,link,icon}) => {
            return (
              <li className="sidebar-item">
                <Link to={('/'+link)} className="sidebar-link"> 
                  <div className="round-16 d-flex align-items-center justify-content-center">
                    <i className={icon}></i>
                  </div>
                  <span className="hide-menu">{name}</span>
                </Link>
              </li>
            );
          })}
        </ul>:""}
  </li>
    )
}
export default SidebarItem;
