import React, { useEffect, useState } from 'react';
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import validator from 'validator';
import Select from 'react-select';

function AddAdminRoles() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState('');

    const [desc, setDesc] = useState();

    const [publishDate, setPublishDate] = useState("");
    const [minDateTime, setMinDateTime] = useState("");
    const [schedulePublish, setSchedulePublish] = useState(false);

    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    

    useEffect(() => {
        setMinDateTime(new Date().toISOString().slice(0, 16));
        const userscreens = window.localStorage.getItem("admin_screens");
        const screensArray = userscreens.split(',');
        if (!screensArray.includes("admin-roles")) {
          navigate("/");
        }

    }, []);


    const resetState = () => {
        setErrors('');
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validator.isEmpty(publishDate)) {
            setErrors('publishDate is required.');
        }

        if (errors == "") {

            // submitForm(name, screen);
            submitForm(publishDate);

        }


        // submitForm(role,name,email,phone,datetime);

        setTimeout(resetState, 2000);

    };




    const submitForm = (publishDate) => {
        setloaderSubmit(true)
        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var id = confirmcode[2];
        const userid = decodeURIComponent(id);
        // var token = localStorage.getItem("authSession")
        // var token = JSON.parse(token);

        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/schedule-demo', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "user_id": userid,
                    "date": publishDate,
                    "message": desc,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.code == "200") {
                            setSuccess("Demo Scheduled succesfully")
                            navigate("/schedule-demo")
                            setloaderSubmit(false)
                        } else {
                            navigate("/add-demo/"+userid)
                            setloaderSubmit(false)
                        }
                    });
                }
            }).
                catch((error) => {
                    setloaderSubmit(false)
                    console.log('error>>>', error)
                });
        });
        return fetch_prods;
    };



    // const isButtonDisabled = !name || !screen;
    const isButtonDisabled = !publishDate;


    return (
        <div class="page-wrapper" id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar />
            <Page title="Schedule Demo" formWidth={true}>
                <div className="container-fluid">
                    <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                        <div className="card-body px-4 py-3">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <h4 className="fw-semibold mb-8">Schedule User Demo</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link className="text-muted" to="/">
                                                    Dashboard
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                            Schedule User Demo
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-3">
                                    <div className="text-center mb-n5">
                                        <img
                                            src="../../dist/images/breadcrumb/ChatBc.png"
                                            alt=""
                                            className="img-fluid mb-n4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="card">
                            <div className="card-body wizard-content">

                                {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}



                                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">

                                <div className="content clearfix">


                                   

                                 
                                    {/* <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Role Name:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Role Name'
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="name" value={name} onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="mb-3 row">
                                        <div className="mb-3">
                                            <label htmlFor="datec">Schedule Date: <span className="danger">*</span></label>
                                            <input type="datetime-local" min={minDateTime}  className="form-control" onChange={(e) => setPublishDate(e.target.value)} id="datec" />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                    <div class="col-12">
                                        <div class="mb-4">
                                        <label htmlFor="videodesc">Message:</label>
                                        <textarea class="form-control" name="" id="videodesc" cols="20" rows="8" value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Enter Message"></textarea>
                                        </div>
                                    </div>
                                    </div>

                                
                                    <div class="actions clearfix">
                                        <ul role="menu" aria-label="Pagination">
                                            <li aria-hidden="false" aria-disabled="false">
                                            <button type="submit" onClick={handleSubmit} disabled={isButtonDisabled} class="mr-5 btn btn-primary text-white float-end">
                                               
                                                    {loaderSubmit ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                        </div>
                                                    ) : (
                                                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                    )}
                                                
                                            </button>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </section>
                </div>

            </Page>
        </div>
    );
}
export default AddAdminRoles;