export async function getData(paramData) {
    try {
        const response = await fetch(paramData.url, {
        //   method: 'GET',
          method: 'POST', //later we'll remove graphql then we will GET method 
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userLoggedAccessToken(),
          },
          mode: 'cors',
          body: paramData?.inputData?JSON.stringify(paramData.inputData):"" ,
        });
        const data = await response.json();
        console.log('data',data);
        return data;
      } catch (error) {
        return error;
        // throw error;
    }
}

export async function postData(paramData) {
    try {
        const response = await fetch(paramData.url, {
        //   method: 'GET',
          method: 'POST', //later we'll remove graphql then we will GET method 
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userLoggedAccessToken(),
          },
          mode: 'cors',
          body: paramData?.inputData?JSON.stringify(paramData.inputData):"" ,
        });

        const data = await response.json();
        return data;
      } catch (error) {
        return error;
        // throw error;
    }
}


export const userLoggedAccessToken = () => {
  try{
    const accessToken = JSON.parse(window.localStorage.getItem("adminauthSession")).accessToken;
    return accessToken;
  } catch(error){
    //@TODO: logout user and redirect to login page
    console.log('redirecting to login ....');
    // window.location.href = "/";
    // history.push({
    //   pathname: '/',
    // });
  }
}
