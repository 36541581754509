import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/dist/css/style.css"
import "./assets/dist/libs/owl.carousel/dist/assets/owl.carousel.min.css"
import SignIn from "./components/pages/SignIn/SignIn";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Users from "./components/pages/Users/Users";
import AdminUsers from "./components/pages/AdminUsers/AdminUsers";
import AddAdminUsers from "./components/pages/AdminUsers/AddAdminUsers";
import EditAdminUsers from "./components/pages/AdminUsers/EditAdminUsers";
import AdminRoles from "./components/pages/AdminRoles/AdminRoles";
import AddAdminRoles from "./components/pages/AdminRoles/AddAdminRoles";
import EditAdminRoles from "./components/pages/AdminRoles/EditAdminRoles";
import EditUsers from "./components/pages/Users/EditUsers";
import Videos from "./components/pages/Videos/Videos";

import ScheduleDemo from "./components/pages/ScheduleDemo/ScheduleDemo";
import AddDemo from "./components/pages/ScheduleDemo/AddDemo";
import EditDemo from "./components/pages/ScheduleDemo/EditDemo";
import AssignUserPackage from "./components/pages/Users/AssignUserPackage";
import OrderCommissions from "./components/pages/Orders/OrderCommissions/OrderCommissions";




const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
      </Routes>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/users/assign-package/:id" element={<AssignUserPackage />} />
      </Routes>
      <Routes>
        <Route path="/admin-users" element={<AdminUsers />} />
      </Routes>
      <Routes>
        <Route path="/add-admin-users" element={<AddAdminUsers />} />
      </Routes>
      <Routes>
        <Route path="/edit-admin-users/:id" element={<EditAdminUsers />} />
      </Routes>
      <Routes>
        <Route path="/admin-roles" element={<AdminRoles />} />
      </Routes>
      <Routes>
        <Route path="/add-roles" element={<AddAdminRoles />} />
      </Routes>
      <Routes>
        <Route path="/edit-roles/:id" element={<EditAdminRoles />} />
      </Routes>

      <Routes>
        <Route path="/edit-user/:id" element={<EditUsers />} />
      </Routes>

      <Routes>
        <Route path="/videos/:userId" element={<Videos />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/pending-videos" element={<Videos />} />
        <Route path="/approved-videos" element={<Videos />} />
        <Route path="/declined-videos" element={<Videos />} />
      </Routes>

      <Routes>
        <Route path="/schedule-demo" element={<ScheduleDemo />} />
      </Routes>

      <Routes>
        <Route path="/add-demo/:userId" element={<AddDemo />} />
      </Routes>


      <Routes>
        <Route path="/edit-demo" element={<EditDemo />} />
      </Routes>

      <Routes>
        <Route path="/orders/commission-detail-report" element={<OrderCommissions />} />
      </Routes>



      


    </BrowserRouter>
  );
};

export default App;
