import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { useNavigate } from 'react-router-dom';
import {useTable, useSortBy,useFlexLayout,useResizeColumns,useExpanded} from "react-table";
import * as apiHelper from 'vs-admin-panel/api-helper';
import * as helper from 'vs-admin-panel/helper';
import moment from "moment";
import { SocialShare } from "../../SocialShare";


const VideosApprovedList = () => {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {

    console.log("sajdain");
   
    fetchVideosData();
  }, [currentPage, rowsperPage, searchValue]);

  const fetchVideosData = async () => {
    const videotype = decodeURIComponent(helper.urlSegment(0));
    
    const videosParam = { url: process.env.REACT_APP_API_URL + '/approved-videos', inputData: {} };

    if (videotype) {
      videosParam.inputData.videotype = videotype;
    }

    videosParam.inputData.currentPage = currentPage;
    videosParam.inputData.rowsPage = rowsperPage;
    videosParam.inputData.search = searchValue;

    const videosData =  await apiHelper.getData(videosParam);
    if(videosData){
        setVideoData(videosData?.data);
        setTotalRows(videosData?.total);
        setCurrentPage(videosData?.currentPage);
        setLoader(true);
    }
  };


  const handleEditClick = (id) => {

    navigate("/edit-Video/"+id)
    // window.open(urls, '_blank');
    
  };


  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    // fetchDeleteData(selectedOrderId)
    // setShowPopup(false);
  };

  const handleApproval = async (eventFire,rowData) => {
    const isChecked = eventFire.target.checked;
    const rowIndex = videoData.findIndex(item => item.id === rowData.id);
    if (rowIndex !== -1) {
      const updatedData = [...videoData];
      updatedData[rowIndex].is_approved= isChecked;
      setVideoData(updatedData);
    }

    const videoPublishData = { url: process.env.REACT_APP_API_URL + '/videos/set-approve', inputData: {isPublish:isChecked,videoId:rowData.id} };
    const videosData =  await apiHelper.postData(videoPublishData);
  };


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
};

const toggleShareView = (row) => {
  setCurrentRow(row);
};

  const Popup = ({onClose, onDelete }) => {
    return (

      <div className="modal-dialog-parent">
        <div class="modal-dialog modal-sm" id="al-danger-alert">
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7"></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">
                You Want to delete Permanently?
                </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
            
            
    );
  };

  const columns = [
    // {
    //   name: "#",
    //   selector: row => row.sno,
    //   sortable: true,
    //   width: '60px',
    // },
    {
      name: "Video",
      selector: row => row.name,
      sortable: true,
      // width: '150px',
    },
    {
      name: "User",
      selector: row => row.uploader_name,
      sortable: true,
      // width: '110px',
    },
    {
      name: "Created Date",
      selector: row => moment(row.created_at).format("MMMM Do YYYY"),
      sortable: true,
      // width: '150px',
    },

    {
      name: "Share",
      selector: (row, i) => row.share,
      sortable: true,
      width:'90px',
      cell: (row) => (
        <div><i class="ti ti-share-2 fs-7 cursor-pointer" onClick={(e)=>toggleShareView(row)}></i></div>
      ),
    },

    {
      name: "Watch Video",
      selector: (row, i) => row.id,
      sortable: false,
      cell: (row) => (
        <div><a className="btn rounded-pill waves-effect waves-light btn-primary" href={process.env.REACT_APP_WEB_URL+'/watch/'+row.id} target="_blank"><i class="ti ti-video fs-5"></i> Watch Video</a></div>
      ),
    },

    {
      name: "Status",
      selector: (row, i) => row.status,
      width:'90px',
      sortable: false,
      cell: (d) => (

        d.status === "Approved" ? (<span className="badge bg-light-success rounded-3 py-8 text-success fw-semibold fs-2">Approved</span>) : d.status === "Pending" ? (<span className="badge bg-light-warning rounded-3 py-8 text-warning fw-semibold fs-2">Pending</span>):(<span className="badge bg-light-danger rounded-3 py-8 text-danger fw-semibold fs-2">Declined</span>)

      ),
    }
    
    // {
    //   name: "Approved?",
    //   selector: row => row.id,
    //   sortable: false,
    //   width: '120px',
    //   cell: (d) => {

    //     return  <div className="d-flex justify-content-center w-100">

    //         <div className="form-check form-switch">
    //           <input
    //             className="form-check-input"
    //             type="checkbox"

    //             onChange={(event) => handleApproval(event, d)}
    //             id={`flexSwitchCheckChecked-${d.id}`}
    //             checked={d.is_approved}


    //           />
    //         </div>

    //     </div>;
    //     }
    // }
    
  
  ]



  
  const data = videoData;

  const tableData = {
    columns,
    data
  };

  const handlePageChange = (page) => {
  
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    setCurrentPage(page);
    setrRowsperPage(newPerPage)

  };

  const handleSearchChange = (event) => {

    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false)

  };




  return (
    <>

<div className="card card-body App">

{showPopup ? 
      <Popup
        onClose={handleClosePopup}
        onDelete={handleDelete}
      />:""}
{(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
{(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}

<div id="complex_header_filter" class="dataTables_filter search_section data-table-extensions-filter">
  <label for="filterDataTable" class="icon "></label>
   
      <input
      type="text"
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      />
      
      </div>

      {loader ?
      
        <DataTableExtensions {...tableData} export={false} print={false} >
          <DataTable
            className="hide-expandable-icons-row"
            columns={columns}
            data={data}                                    
            defaultSortField="id"
            sortIcon={<SortIcon />}
            defaultSortAsc={true}
            highlightOnHover
            dense

            expandableRows
            expandableRowsComponent={SocialShare} 
            // expandableIcon={{
            //   collapsed: <Add />,
            //   expanded: <Subtract />,
            // }}

            expandableRowExpanded={(row) => (row === currentRow)}

            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}


          />
        </DataTableExtensions>
        : <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      }

   

        </div>

    </>
  );
};

export default VideosApprovedList;
