import React, { useEffect, useState } from "react";
import * as helper from "../../../../helpers/helper";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import SortIcon from "@material-ui/icons/ArrowDownward";
import ExpandOrderCommissionView from "./ExpandOrderCommissionView";
import moment from "moment";
import styles from "./OrderCommissionRerportList.module.css";
const XLSX = require("sheetjs_xlsx");

function OrderCommissionRerportList() {
  const [invoicesData, setInvoicesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [expandViewType, setExpandViewType] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [exportingSheetLoader, setExportingSheetLoader] = useState(false);

  useEffect(() => {
    fetchOrderCommissionsData();
  }, [currentPage, rowsperPage]);

  useEffect(() => {
    fetchOrderCommissionsData();
  }, [currentPage, rowsperPage]);

  useEffect(() => {
    // if (
    //   (!startDate?.length && !endDate?.length) ||
    //   (startDate?.length >= 1 && endDate?.length >= 1)
    // ) {
    fetchOrderCommissionsData();
    // }
  }, [startDate, endDate]);

  const generateCommissionReportSheet = (dataToGenerateSheet) => {
    const sheetFileName = `order-commission-report-${moment().format(
      "YYYY-MM-DD"
    )}.xlsx`;

    // Define custom headings and column mapping
    const customHeadings = [
      "Transaction ID",
      "Date of Charge",
      "Client Name",
      "Transaction Amount",
    ];
    const columnMapping = [
      "transaction_id",
      "deduction_date",
      "client_name",
      "commission_amount",
    ];

    // Create a custom worksheet
    const customWorksheet = XLSX.utils.json_to_sheet(
      dataToGenerateSheet.map((row) => {
        // Format the "Date of Charge" column using moment.js
        const formattedDate = moment(row["deduction_date"]).format(
          "MMMM Do YYYY"
        );
        return columnMapping.map((column) =>
          column === "deduction_date" ? formattedDate : row[column]
        );
      })
    );

    // Rename columns with custom headings
    customWorksheet["A1"].v = customHeadings[0];
    customWorksheet["B1"].v = customHeadings[1];
    customWorksheet["C1"].v = customHeadings[2];
    customWorksheet["D1"].v = customHeadings[3];

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      customWorksheet,
      "Order Commission Report"
    );

    // Write the workbook to a file
    XLSX.writeFile(workbook, sheetFileName);
  };

  const handleSubmitExport = () => {
    fetchOrderCommissionsData(searchValue, true);
  };

  const fetchOrderCommissionsData = (
    searchedValue = null,
    directExportSheet = false
  ) => {
    if (directExportSheet === true) {
      setExportingSheetLoader(true);
    } else {
      setLoader(true);
    }
    fetch(process.env.REACT_APP_API_URL + "/admin/commission-orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.userLoggedAccessToken(),
      },

      body: JSON.stringify({
        currentPage: currentPage,
        rowsPage: rowsperPage,
        search: searchedValue != null ? searchedValue : searchValue,
        startDate: startDate,
        endDate: endDate,
        exportSheet: directExportSheet,
        time_offset: new Date().getTimezoneOffset(),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (directExportSheet === true) {
          //call to export sheet
          // setExportingSheetLoader(false);

          generateCommissionReportSheet(data.data);
        } else {
          setInvoicesData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        if (directExportSheet === true) {
          setExportingSheetLoader(false);
        } else {
          setLoader(false);
        }
      });
  };

  const closeFromChild = () => {
    setCurrentRow(null);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (value?.length > 0) {
      // Set a new timeout to call the function after a delay (e.g., 500 milliseconds)
      const timeout = setTimeout(() => {
        fetchOrderCommissionsData(value);
      }, 700);

      setTypingTimeout(timeout);
    } else {
      fetchOrderCommissionsData(value);
    }
  };

  const toggleExpandView = (row, expandType) => {
    if (row?.id === currentRow?.id && expandType != expandViewType) {
      setCurrentRow(row);
    } else {
      setCurrentRow(currentRow == row ? null : row);
    }
    setExpandViewType(expandType);
  };

  const columns = [
    // {
    //   name: "Amount",
    //   selector: (row, i) => row.amount,
    //   sortable: false
    // },
    // {
    //   name: "Invoice Number",
    //   selector: "invoice_number",
    //   sortable: false,
    // },
    {
      name: "Transaction ID",
      sortable: false,
      cell: (row) => <span>{row?.transaction_id}</span>,
    },
    {
      name: "Date of Charge",
      sortable: false,
      cell: (row) => (
        <span>{moment(row?.deduction_date).format("MMMM Do YYYY")}</span>
      ),
    },

    {
      name: "Client Details",
      width: "230px",
      sortable: false,
      cell: (d) => {
        return (
          <div className="d-flex align-items-center">
            <div className="user-meta-info">
              <h6 className="mb-1 fw-semibold chat-title">{d?.client_name}</h6>
              <small>{d?.client_email}</small>
            </div>
          </div>
        );
      },
    },
    {
      name: "Transaction Amount",
      sortable: false,
      cell: (row) => <span>{helper.formatNumber(row?.commission_amount)}</span>,
    },
    {
      name: "Action",
      selector: (row, i) => row.invoice_url,
      sortable: false,
      cell: (row) => {
        return (
          <div className="action-btn tbl-action-buttons">
            <a
              href="javascript:void(0)"
              onClick={(e) => toggleExpandView(row, 1)}
              className={"text-dark ms-2"}
            >
              <i className="ti ti-eye-check fs-7" />
            </a>
          </div>
        );
      },
      maxWidth: "100px",
    },
  ];

  function setstartDateValue(value) {
    setStartDate(value);
    if (value) {
      document.getElementById("endDate").min = value;
    }
  }

  function setendDateValue(value) {
    setEndDate(value);
    if (value) {
      document.getElementById("startDate").max = value;
    }
  }

  const handleSubmitClear = (e) => {
    setCurrentPage(1);
    setrRowsperPage(10);
    setSearchValue("");
    setStartDate("");
    setEndDate("");
    fetchOrderCommissionsData("");
  };

  const data = invoicesData;

  const tableData = {
    columns,
    data,
  };

  const isClearDisabled =
    !searchValue?.length && !startDate?.length && !endDate?.length;

  return (
    <>
      <div className="card card-body App order-commission-report-list">
        {errors ? (
          <div class="alert alert-danger" role="alert">
            <strong>Error</strong> {errors}{" "}
          </div>
        ) : (
          ""
        )}
        {success ? (
          <div class="alert alert-success" role="alert">
            <strong>Success</strong> {success}{" "}
          </div>
        ) : (
          ""
        )}

        {/* <div className="row">
          <div className="col-xs-12 col-lg-6"></div>
          <div className="col-xs-12 col-lg-6"></div>
        </div> */}

        <div className="col-12">
          <div className={`d-flex ${styles.orderCommissionFiltersBy}`}>
            <div className={`me-3 ${styles.filterFields}`}>
              <label htmlFor="searchText">Search</label>
              <input
                className="form-control"
                id="searchText"
                placeholder="Type related to details"
                type="text"
                value={searchValue}
                onChange={(event) => {
                  handleInputChange(event);
                }}
              />
            </div>

            <div className={`me-1 ${styles.filterFields}`}>
              <label htmlFor="startDate">Start Date:</label>
              <input
                className="form-control"
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setstartDateValue(e.target.value)}
                min=""
              />
            </div>

            <div className={`me-3 ${styles.filterFields}`}>
              <label htmlFor="endDate">End Date:</label>
              <input
                className="form-control"
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setendDateValue(e.target.value)}
                min=""
              />
            </div>

            <div className={styles.filterButtons}>
              <button
                type="button"
                onClick={handleSubmitClear}
                disabled={isClearDisabled || loader}
                className="mr-5 btn btn-primary text-white"
              >
                <i className="ti ti-circle-x me-1 fs-4"></i>Clear
              </button>

              <button
                type="button"
                disabled={exportingSheetLoader || loader}
                onClick={handleSubmitExport}
                className="mr-5 btn btn-primary text-white"
              >
                <div class="d-flex align-items-center">
                  {exportingSheetLoader ? (
                    <div>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </div>
                  ) : (
                    <>
                      <i className="ti ti-download me-1 fs-4"></i>Export
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>

        {!loader ? (
          <div>
            <DataTableExtensions {...tableData} export={false} print={false}>
              <DataTable
                columns={columns}
                data={data}
                className="hide-expandable-icons-row"
                noHeader
                defaultSortField="id"
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                expandableRows
                expandableRowsComponent={(props) =>
                  expandViewType === 1 ? (
                    <ExpandOrderCommissionView
                      {...props}
                      closeFromChild={closeFromChild}
                    />
                  ) : (
                    <></>
                  )
                }
                // expandableIcon={{
                //   collapsed: <Add />,
                //   expanded: <Subtract />,
                // }}

                expandableRowExpanded={(row) => row === currentRow}
              />
            </DataTableExtensions>
          </div>
        ) : (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OrderCommissionRerportList;
