import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
function SignInForm() { 
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword ]= useState('');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [userdata, setUserdata] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validator.isEmpty(email)) {
            setErrors('Email is required.');
        } else if (!validator.isEmail(email)) {
            setErrors('Invalid email address.');
        }

        if (validator.isEmpty(password)) {
            setErrors('Phone is required.');
        }else if (!validator.isLength(password, { min: 8 })) {
            setErrors('Password must be at least 8 characters long.');
        }

          if (errors == "") {
            submitForm(email,password);
          }
        
    };

    const isButtonDisabled = !email || !password;

    const resetState = () => {
      setErrors('');
  };

    const submitForm = (email,password) => {
      setLoader(true);

      const fetch_prods = new Promise((resolve, reject) => {
          fetch(process.env.REACT_APP_API_URL + '/admin-login', {
              method: 'POST',
              mode: 'cors',
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  "email": email,
                  "password": password
              })
          }).then(response => {
              if (response.ok) {
                  response.json().then(json => {
                      if (json.code == "200") {

                         //console.log(userinfo,"sajdsainnnn") 
                          setSuccess("Login succesfully")
                          setUserdata(json.user)
                          var userinfo=json.user;
                          window.localStorage.setItem("adminauthSession", JSON.stringify(json.data));
                          window.localStorage.setItem("admin_name",userinfo.admin_name);
                          window.localStorage.setItem("admin_email",userinfo.admin_email);
                          window.localStorage.setItem("admin_picture",userinfo.admin_picture);
                          window.localStorage.setItem("admin_screens",userinfo.roles_screens);
                          window.localStorage.setItem("admin_id",userinfo.admin_id);
                          navigate("/dashboard");
                          setLoader(false)
                      } else {
                        setErrors(json.message)
                        navigate("/");
                        setLoader(true)
                        setTimeout(resetState, 2000);
                      }
                  });
              }
          }).
              catch((error) => {
                setErrors(error.message)
                setLoader(false)
                  console.log('error>>>', error)
              });
      });
      return fetch_prods;
  };



return (    
      <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
        <div className="col-sm-8 col-md-6 col-xl-9">
          <h2 className="mb-3 fs-7 fw-bolder">
          Administrator Sign In - Video Squirrel
         
          </h2>
          {/* <p className=" mb-9">
            Are you ready to create and discover new opportunities, track your
            sales&nbsp;and cash out on your progress? We bet you are.
          </p> */}
          <div className="position-relative text-center my-4">
            <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">
              Sign in
            </p>
            <span className="border-top w-100 position-absolute top-50 start-50 translate-middle" />
          </div>

          {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
          {(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}


          <form>
            <div className="LoginForm_info__3sujD" />
            <div className="mb-3">
              <label htmlFor="email-input" className="form-label">
                EMAIL ADDRESS OR PHONE NUMBER
              </label>
              <input
                className="form-control"
                aria-describedby="emailHelp"
                id="email-input"
                name="email"
                value={email} onChange={(e) => setEmail(e.target.value)}
                autoCapitalize="none"
                autoCorrect="false"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password-input" className="form-label">
                PASSWORD
              </label>
              <input
                className="form-control"
                id="password-input"
                type="password"
                name="password"
                value={password} onChange={(e) => setPassword(e.target.value)}
                autoCapitalize="none"
                autoCorrect="false"
              />
            </div>
            <button
              type="submit"
              onClick={handleSubmit} 
              className="btn btn-primary w-100 py-8 mb-4 rounded-2"
              intent="primary"
              disabled={isButtonDisabled} 
            > 
              {loader ? (<div><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</div>) : (<div>Sign In!</div>)}
            </button>
          </form>
        </div>
      </div>
  ); 

}; 

export default SignInForm;