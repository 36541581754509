import React from "react";
import OnboardingPage from "../../layouts/Onboarding/OnboardingPage";
import SignInForm from "../SignIn/SignInForm";
function SignIn() {
    return (
        <>
        <OnboardingPage title="Video Squirrel - Sign In">
         <SignInForm/>
        </OnboardingPage>
        </>
    );
};
export default SignIn;
