import React from "react";
import SidebarVideoSquirrelLogo from "../../layouts/Sidebar/SidebarVideoSquirrelLogo";
import SidebarGroup from "../../layouts/Sidebar/SidebarGroup";
import SidebarItem from "../../layouts/Sidebar/SidebarItem";
import { useResponsive } from "react-hooks-responsive";
import { useNavigate } from 'react-router-dom';
import * as helper from 'vs-admin-panel/helper';

function Sidebar({ onNavBarClick}) {
  const navigate = useNavigate();
  const { size } = useResponsive({ xs: 0, sm: 480, md: 1200 });
  const collapsed = size !== "md";
  const windowLocation = window.location.pathname.split("/").filter((l) => !!l)[0];
  const activewindowLocation = decodeURIComponent(helper.urlSegment(1));

  const profileSettingsSubMenu = [
    { link: 'settings/profile', icon: 'ti ti-arrow-right', name: 'Profile Settings' },
  ];
  const videoSubMenu = [

    { link: 'pending-videos', icon: 'ti ti-arrow-right', name: 'Pending Videos' },
    { link: 'approved-videos', icon: 'ti ti-arrow-right', name: 'Approved Videos' },
    { link: 'declined-videos', icon: 'ti ti-arrow-right', name: 'Declined Videos' },

  ];

  const ordersSubsMenu = [
    // { link: 'commission-detail-report', icon: 'ti ti-basket-dollar', name: 'Commission Detail Report' },
    { link: 'orders/commission-detail-report', icon: 'ti ti-arrow-right', name: 'Commission Detail Report' },
  ];




  const userscreens = window.localStorage.getItem("admin_screens");
  const screensArray = userscreens?.split(',');

  

  return (
    <SidebarGroup onNavBarClick={onNavBarClick}>
      <li class="nav-small-cap">
        <i class="ti ti-dots nav-small-cap-icon fs-4"></i></li>

        {(screensArray?.includes("dashboard"))?<SidebarItem
            active={windowLocation === "dashboard"}
            icon="ti ti-aperture"
            label="Dashboard"
            collapsed={collapsed}
            onClick={() => {
              navigate("/dashboard");
            }}
            isOnBoardingComplete = {true}
          />:""}


          {(screensArray?.includes("orders"))?<SidebarItem
            active={windowLocation === "orders"}
            icon="ti ti-basket-filled"
            childMenu={ordersSubsMenu}
            label="Orders"
            isSubMenu={true}
            parentMenuLink="orders"
            collapsed={collapsed}
            onClick={() => {
              navigate("/orders");
            }}
            isOnBoardingComplete = {true}
          />:""}

          {(screensArray?.includes("users"))?<SidebarItem
            active={windowLocation === "users"}
            icon="ti ti-users"
            label="Users"
            collapsed={collapsed}
            onClick={() => {
              navigate("/users");
            }}
            isOnBoardingComplete = {true}
          />:""}

         {(screensArray?.includes("admin-users"))?<SidebarItem
            active={windowLocation === "admin-users" || windowLocation === "add-admin-users" }
            icon="ti ti-user-check"
            label="Administrator"
            collapsed={collapsed}
            onClick={() => {
              navigate("/admin-users");
            }}
            isOnBoardingComplete = {true}
          />:""}

         {(screensArray?.includes("admin-roles"))?<SidebarItem
            active={windowLocation === "admin-roles" || windowLocation === "add-roles"}
            icon="ti ti-user-shield"
            label="Roles"
            collapsed={collapsed}
            onClick={() => {
              navigate("/admin-roles");
            }}
            isOnBoardingComplete = {true}
          />:""}

          {(screensArray?.includes("videos"))?<SidebarItem
            active={windowLocation === "pending-videos" || windowLocation === "approved-videos" || windowLocation === "declined-videos"}
            icon="ti ti-video"
            label="Videos"
            collapsed={collapsed}
            childMenu={videoSubMenu}
            onClick={() => {
              navigate("/videos");
            }}
            isSubMenu={true}
            parentMenuLink="videos"
            isOnBoardingComplete = {true}
          />:""}

         {(screensArray?.includes("admin-roles"))?<SidebarItem
            active={windowLocation === "schedule-demo" || windowLocation === "schedule-demo"}
            icon="ti ti-user-shield"
            label="Schedule Demo"
            collapsed={collapsed}
            onClick={() => {
              navigate("/schedule-demo");
            }}
            isOnBoardingComplete = {true}
          />:""}

     

         {/* {(screensArray?.includes("videos"))?<SidebarItem

            active={windowLocation === "pending-videos"}
            icon="ti ti-video"
            label="Pending Videos"
            collapsed={collapsed}
            onClick={() => {
              navigate("/pending-videos");
            }}
            isOnBoardingComplete = {true}
          />:""}

          {(screensArray?.includes("videos"))?<SidebarItem
            active={windowLocation === "approved-videos"}
            icon="ti ti-video"
            label="Approved videos"
            collapsed={collapsed}
            onClick={() => {
              navigate("/approved-videos");
            }}
            isOnBoardingComplete = {true}
          />:""}


          {(screensArray?.includes("videos"))?<SidebarItem
            active={windowLocation === "declined-videos"}
            icon="ti ti-video"
            label="Declined Videos"
            collapsed={collapsed}
            onClick={() => {
              navigate("/declined-videos");
            }}
            isOnBoardingComplete = {true}
          />:""} */}
     
     
       
         {/* <SidebarItem
        active={windowLocation === "settings"}
        icon="ti ti-settings"
        label="Settings"
        collapsed={collapsed}
        childMenu={profileSettingsSubMenu}
        onClick={() => {
          navigate("/settings/profile");
        }}
        isSubMenu={true}
        isOnBoardingComplete = {true}
      /> */}
    </SidebarGroup>
  );
}
export default Sidebar;
