import React from "react";
function VideoPageHeader() {
  return (
//      <div className="Toolbar_toolbar__MNKTm">
//    <h2>User Management</h2>
//   <div className="Toolbar_toolbarGroups__LDh29">
//    </div>
// </div>

  <div className="Toolbar_toolbar__MNKTm">
    <h2>Videos Management</h2>
    <div className="Toolbar_toolbarGroups__LDh29">
    <div className="Toolbar_toolbarGroups__tlq+H">
    <div className="FilterToolbar_filterToolbar__M0+T1">
    </div>
  </div>
    </div>

</div>
  );
}

export default VideoPageHeader;
