import React, { useEffect, useState } from 'react';
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import StatsDashboard from "../Dashboard/StatsDashboard";
import { useLocation } from 'react-router-dom';

function Dashboard() {
  const location = useLocation();
  const [sidebarType, setSidebarType] = useState('full');
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState(false);
  const onNavBarClick = () =>{
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  } 
  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
      <Sidebar onNavBarClick = {onNavBarClick}/>
      <Page title="Dashboard" onNavBarClick = {onNavBarClick}>
       <StatsDashboard />
      </Page>
    </div>
  );
}
export default Dashboard;
