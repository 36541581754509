import React, { useState, useEffect, useRef } from "react";
import {FacebookShareButton, TwitterShareButton, PinterestShareButton, FacebookIcon, TwitterIcon, PinterestIcon } from "react-share";
// import instagramIcon from "../../../assets/instalogo.png"
import instagramIcon from "../../assets/instagram.png"

import * as helper from 'vs-admin-panel/helper';


const SocialShare = (props) => {
  const { data } = props;

  const copyButtonDefaultText = 'Copy Code';
  const [copyButtonText, setCopyButtonText] = useState(copyButtonDefaultText);
  const [socialSharingCode,setSocialSharingCode] = useState('');

    const copyScriptRef = useRef(null);
    const copyVideoUrlRef = useRef(null);
    const copyCodeIcon = 'ti ti-clipboard';
    const copyUrlDefaultIcon = 'ti ti-clipboard';
    const copyUrlDefaultText = 'Copy Link';
    const copyCodeDefaultText = 'Copy Code';
    const [copyUrlText, setCopyUrlText] = useState(copyUrlDefaultText);
    const [copyCodeText, setCopyCodeText] = useState(copyCodeDefaultText);
    const [copyButtonIcon, setcopyButtonIcon] = useState(copyCodeIcon);
    const [copyUrlIcon, setCopyUrlIcon] = useState(copyUrlDefaultIcon);
    const [copyUrlTimeoutId, setCopyUrlTimeoutId] = useState(null);
    const [copyCodeTimeoutId, setCopyCodeTimeoutId] = useState(null);

  const successVideoId = data.id
  let videoUrl = process.env.REACT_APP_WEB_URL + '/watch/' + successVideoId;
  let videoIframe = "";
  useEffect(() => {
    console.log('data?.app_id',data?.app_id);
    setSocialSharingCode(data?.app_id!=null?helper.showWidgetSharingCode({app_id:data.app_id}):"");
  });

  const copyScriptTag = async () => {
    try {
        const textToCopy = copyScriptRef.current.value;
        await navigator.clipboard.writeText(textToCopy);
        setcopyButtonIcon('ti ti-check');
        setCopyCodeText('Copied!');
    } catch (error) {
        setcopyButtonIcon('ti ti-x');
        setCopyCodeText('Failed!');
        console.error('Error copying text:', error);
    }

    if (copyCodeTimeoutId) {
        clearTimeout(copyCodeTimeoutId);
    }
    const newCopyCodeTimeoutId = setTimeout(function (){ setcopyButtonIcon(copyCodeIcon); setCopyCodeText(copyCodeDefaultText); }, 3000);
    setCopyCodeTimeoutId(newCopyCodeTimeoutId);
  };

  const copyVideoUrl = async () => {

        
    try {
      const textToCopy = copyVideoUrlRef.current.value;
      await navigator.clipboard.writeText(textToCopy);
      setCopyUrlIcon('ti ti-check');
      setCopyUrlText('Copied!');

    } catch (error) {
      setCopyUrlIcon('ti ti-x');
      setCopyUrlText('Failed!');
      console.error('Error copying text:', error);
    }
    if (copyUrlTimeoutId) {
        clearTimeout(copyUrlTimeoutId);
    }
    const newCopyUrlTimeoutId = setTimeout(function (){setCopyUrlIcon(copyUrlDefaultIcon); setCopyUrlText(copyUrlDefaultText); }, 3000);
    setCopyUrlTimeoutId(newCopyUrlTimeoutId);
  };

  return (
    <center>
    <div className="socialshare">

    <span >Share on social media:</span>
    <br/>
    <FacebookShareButton style={{"padding-right":"2px"}} url={videoUrl}><FacebookIcon size={32} round={true} /></FacebookShareButton>
    <TwitterShareButton style={{"padding-right":"2px"}} url={videoUrl}><TwitterIcon size={32} round={true} /></TwitterShareButton >
    <PinterestShareButton url={videoUrl}><PinterestIcon size={32} round={true} /></PinterestShareButton>
    <br/>
    <br/>
    <div >

    <div class="col-6">
            <div className="mb-3">
                <label htmlFor="wfirstName2"> Watch Link</label>
                <div className="p-r">
                    <input type="text" ref={copyVideoUrlRef} disabled className="form-control required" id="wfirstName2" defaultValue={videoUrl} name="watch_link" />
                    <div title="Copy to clip board" className="fs-2 p-a copy-url-field-icon" onClick={copyVideoUrl}>
                        <i className={'fs-7 '+copyUrlIcon}></i>
                        {copyUrlText}
                    </div>
                </div>
                
            </div>
        </div>

        <div class="col-6">
            <div class="mb-4">
                <label htmlFor="wfirstName2"> Embed On Your Website:<span className="danger"></span>
                </label>

                <div className="p-r">
                    <textarea ref={copyScriptRef} rows={3} disabled={true} defaultValue={socialSharingCode} className="form-control p-7"></textarea>

                    {/* <textarea disabled ref={copyScriptRef} class="form-control p-7" name="" id="" cols="20" rows="3" placeholder="150, Ring Road" value={videoIframe} /> */}
                    <div title="Copy to clip board" disabled={!socialSharingCode} className={'fs-2 p-a copy-url-field-icon'+(!socialSharingCode?" pointer-events-none":"")} onClick={copyScriptTag}>
                        <i className={'fs-7 ' + copyButtonIcon}></i>
                        {copyCodeText}
                    </div>
                </div>                        
            </div>


            {/* <div className="d-flex justify-content-end">
              <button type="button" disabled={!socialSharingCode} onClick={copyScriptTag} className="btn btn-primary text-white">{copyButtonText}</button>
            </div> */}

        </div>

        
    </div>


    </div>
    </center>
  );
};
export default SocialShare;
