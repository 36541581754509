import React from "react";
import { Link } from "react-router-dom";
import whiteVideoSquirrelLogo from "../../../assets/Logonew.svg";
import {SidebarVideoSquirrelLogo} from "./SidebarVideoSquirrelLogo";

function SidebarGroup({ onNavBarClick,title, children }) {
  return (
    <aside class="left-sidebar">

      <div class="brand-logo d-flex align-items-center justify-content-between">
        <Link to="/" class="text-nowrap logo-img">
          <img src={whiteVideoSquirrelLogo} class="dark-logo" width="180" alt="" />
        </Link>
        <div class="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse" onClick={onNavBarClick}>
          <i class="ti ti-x fs-8"></i>
        </div>
      </div>
      <nav className="sidebar-nav scroll-sidebar" data-simplebar="init"><div className="simplebar-wrapper selected" style={{ margin: '0px -24px' }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer" /></div><div className="simplebar-mask selected"><div className="simplebar-offset selected" style={{ right: '0px', bottom: '0px' }}><div className="simplebar-content-wrapper selected" tabIndex={0} role="region" aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden scroll' }}><div className="simplebar-content selected" style={{ padding: '0px 24px' }}>
        <ul id="sidebarnav">
          {children}
        </ul>
      </div>
      </div>
      </div>
      </div>
      </div>
      </nav>

    </aside>
  );
}

export default SidebarGroup;
