import React, { useEffect, useState } from "react";
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import VideoPageHeader from "../Videos/VideoPageHeader";
import VideoList from "../Videos/VideosList";
import VideosPendingList from "../Videos/VideosPendingList";
import VideosApprovedList from "../Videos/VideosApprovedList";
import VideosDeclinedList from "../Videos/VideosDeclinedList";

import * as helper from 'vs-admin-panel/helper';


function Videos() {

  const navigate = useNavigate();
  const [sidebarType, setSidebarType] = useState('full');
  const [videoType, setVideoType] = useState('');

  const onNavBarClick = () =>{
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  } 

  // const param = helper.urlSegment(1);

  const param =  window.location.pathname.split("/").filter((l) => !!l)[0];

  console.log(param,"hai yahan pe")

  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
    <Sidebar onNavBarClick = {onNavBarClick} />
    <Page title="Videos" onNavBarClick = {onNavBarClick}  flex>

      {(param=="pending-videos")? <div><VideoPageHeader/><VideosPendingList/></div>:(param=="approved-videos")?<div><VideoPageHeader/><VideosApprovedList/></div>:(param=="declined-videos")?<div><VideoPageHeader/><VideosDeclinedList/></div>:<div><VideoPageHeader/><VideoList/></div>}
      
    </Page>
    </div>
  );
}

export default Videos;
