import React, { useState } from "react";
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import UserPageHeader from "../../pages/Users/UserPageHeader";
import UserList from "../../pages/Users/UserList";

function Users() {

  const navigate = useNavigate();
  const [sidebarType, setSidebarType] = useState('full');
  const onNavBarClick = () =>{
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  } 
  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
    <Sidebar onNavBarClick = {onNavBarClick} />
    <Page title="Users" onNavBarClick = {onNavBarClick}  flex>
      <UserPageHeader/>
      <UserList/>
    </Page>
    </div>
  );
}

export default Users;
