import React, { useEffect, useState } from 'react';
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import validator from 'validator';
import validateField from  "../../../../src/helpers/AllValidtions";
import ActivityLog from "../../../helpers/ActivityLog";

function EditUsers() {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmpasswordError, setConfirmPasswordError] = useState('');
    const [showPopup, setShowPopup] = useState(false);


    useEffect(() => {

        
        const userscreens = window.localStorage.getItem("admin_screens");
        const screensArray = userscreens.split(',');
        if (!screensArray.includes("admin-users")) {
          navigate("/");
        }


        fetchAdminData();

    }, []);


    
  const fetchAdminData = () => {
    let path = window.location.pathname;
    var confirmcode = path.split("/");
    var id = confirmcode[2];
    const userid = decodeURIComponent(id);

    // var token =localStorage.getItem("authSession")
    // var token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL+'/find-user',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
       //   Authorization: 'Bearer '+token.accessToken,
        },
        body: JSON.stringify({"id":userid}),
      })
        .then((response) => response.json())
        .then((data) => {
            // setVideotdata(data.data)
            // setLoader(true)
            const leadData=data.data
            setName(leadData.name)
            setEmail(leadData.email)
            setPhone(leadData.contact_number)
            setType(leadData.type)
            setStatus(leadData.status)

           

        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
};



    const resetState = () => {
        setErrors('');
    };


    const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (validator.isEmpty(email)) {
    //         setErrors('Email is required.');
    //     } else if (!validator.isEmail(email)) {
    //         setErrors('Invalid email address.');
    //     }
        setShowPopup(true);
        // submitForm(status,email);
    //     setTimeout(resetState, 2000);
    };


    const Popup = ({onClose, onDelete }) => {
        return (
          <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{display: "block"," padding-left": "0px"}} aria-modal="true" role="dialog">
        
          <div class="modal-dialog modal-sm" >
            <div class="modal-content modal-filled bg-light-danger">
              <div class="modal-body p-4">
                <div class="text-center text-danger">
                <i class="ti ti-x fs-7"  onClick={onClose}></i>
                  <h4 class="mt-2">Are You sure?</h4>
                  <p class="mt-3">
                  You Want to Edit?
                  </p>
                  <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                  <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    
          
                
        );
      };

      const handleClosePopup = () => {
        setShowPopup(false);
      };

      const handleDelete = () => {
        // fetchDeleteData(selectedOrderId)
        submitForm(status,email);
        setShowPopup(false);
      };


    const submitForm = (status,email) => {
        const userLog = new ActivityLog();
        setloaderSubmit(true)
        // var token = localStorage.getItem("authSession")
        // var token = JSON.parse(token);
        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var id = confirmcode[2];
        const userid = decodeURIComponent(id);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/update-user', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "status": status,
                    "email": email,
                    "password": password,
                    "id": userid,
                    "admin_id":localStorage.getItem('admin_id')
                    
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.code == "200") {

            
                            setSuccess("User Upadted succesfully")
                            // navigate("/users")
                            window.location.reload();
                            setloaderSubmit(false)

                        } else {
                            // navigate("/add-admin-users")
                            setErrors(json.message)
                            setloaderSubmit(false)
                        }
                    });
                }
            }).
                catch((error) => {
                    setloaderSubmit(false)
                    console.log('error>>>', error)
                });
        });
        return fetch_prods;
    };

    const handleEmailChange = (e) => {
        var value = e.target.value;
        var rule = "email";
        setEmail(value);
        validateField(value, rule, setEmailError);

    };

    const handlePasswordChange = (e) => {
        var value = e.target.value;
        var rule = "password";
        setPassword(value);
        validateField(value, rule, setPasswordError);
    }

    const handleConfirmPasswordChange = (e) => {
        var value = e.target.value;
        if(value !==password){
            setConfirmPassword(value)
            setConfirmPasswordError("New password and confirm password did not matched")
        } else{

            setConfirmPasswordError("")
            setConfirmPassword(value)

        }
       
    }

    const isButtonDisabled = !email || emailError || passwordError || confirmpasswordError;


    return (
        <div class="page-wrapper" id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar />
            <Page title="Edit Users" formWidth={true}>
                <div className="container-fluid">
                    <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                        <div className="card-body px-4 py-3">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <h4 className="fw-semibold mb-8">Edit User</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link className="text-muted" to="/dashoard">
                                                    Dashboard
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                Edit User
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-3">
                                    <div className="text-center mb-n5">
                                        <img
                                            src="../../dist/images/breadcrumb/ChatBc.png"
                                            alt=""
                                            className="img-fluid mb-n4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="card">
                            <div className="card-body wizard-content">

                                
                        {showPopup ? 
                            <Popup
                                onClose={handleClosePopup}
                                onDelete={handleDelete}
                            />:""}



                                {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}
                                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">
                                <div className="content clearfix">

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label htmlFor="wfirstName2">User Name:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Name'
                                                    className="form-control"
                                                    type="text"
                                                    disabled
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="name" value={name} onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label htmlFor="wfirstName2">User Email:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Email'
                                                    className="form-control"
                                                    type="email"
                                                    defaultValue="bootstrap@example.com"
                                                    id="example-email-input"
                                                    name="email" value={email} onChange={handleEmailChange}
                                                />
                                              {(emailError?<small className="text-danger">{emailError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label htmlFor="wfirstName2">User Type:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Name'
                                                    className="form-control"
                                                    type="text"
                                                    disabled
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="type" value={type} onChange={(e) => setType(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Contact Number:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Phone'
                                                    className="form-control"
                                                    id="example-tel-input"
                                                    disabled
                                                    min="1"
                                                    name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Password:</label>
                                                <input
                                                    placeholder='Enter Password'
                                                    className="form-control"
                                                    type="Password"
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="phone" value={password} onChange={handlePasswordChange}
                                                />
                                                 {(passwordError?<small className="text-danger">{passwordError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Confirm Password:</label>
                                                <input
                                                    placeholder='Enter Password Again'
                                                    className="form-control"
                                                    type="Password"
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="phone" value={confirmpassword} onChange={handleConfirmPasswordChange}
                                                />
                                                    {(confirmpasswordError?<small className="text-danger">{confirmpasswordError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Select Status:<span className="danger">*</span></label>
                                                <select className="form-select col-12" id="example-month-input2" name="status" value={status} onChange={(e) => setStatus(e.target.value)} >
                                                    <option value="Active">Active</option>
                                                    <option value="Unverified">Unverified</option>
                                                    <option value="Inactive">Inactive</option>
                                                    <option value="Terminated">Terminated</option>
                                                    
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="actions clearfix">
                                        <ul role="menu" aria-label="Pagination">
                                            <li aria-hidden="false" aria-disabled="false">
                                            <button type="button" onClick={handleSubmit} disabled={isButtonDisabled} class="mr-5 btn btn-primary text-white float-end">
                                               
                                                    {loaderSubmit ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                        </div>
                                                    ) : (
                                                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                    )}
                                                
                                            </button>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </section>
                </div>

            </Page>
        </div>
    );
}
export default EditUsers;