import React from "react";
function AdminUsersPageHeader() {
  return (
    <div className="Toolbar_toolbar__MNKTm">
  <h2>Admin User Management</h2>
  <div className="Toolbar_toolbarGroups__LDh29">
  <div className="Toolbar_toolbarGroups__tlq+H">
  <div className="FilterToolbar_filterToolbar__M0+T1">
  </div>
  <div >
    <a href="/add-admin-users" className="add-lead-btn"> <i class="ti ti-plus"></i>Add Admin User</a>
  </div>
</div>
  </div>
</div>
  );
}

export default AdminUsersPageHeader;
