import React, { useEffect,useState } from "react";
import Page from "../../layouts/Page/Page";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import AssignPackagePageHeader from "./AssignPackagePageHeader";
// import UserList from "./UserList";
import ExpendAssignUserPackage from "./ExpendAssignUserPackage";



function AssignUserPackage() {

  const navigate = useNavigate();
  const [sidebarType, setSidebarType] = useState('full');
  const [userDataAccount, setUserDataAccount] = useState(null);

  const onNavBarClick = () =>{
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  } 
  return (

<div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
    
    {/* <div class="page-wrapper" id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed"> */}

            <Sidebar onNavBarClick = {onNavBarClick} />
            <Page title="Add Admin Users Roles" formWidth={true}>
                <div className="container-fluid">
                    <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                        <div className="card-body px-4 py-3">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <h4 className="fw-semibold mb-8">Assigned Package {userDataAccount?.email?"("+userDataAccount?.email+")":""}</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link className="text-muted" to="/">
                                                    Dashboard
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link className="text-muted" to="/users">
                                                    Users
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                              Assigned Package
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-3">
                                    <div className="text-center mb-n5">
                                        <img
                                            src="../../dist/images/breadcrumb/ChatBc.png"
                                            alt=""
                                            className="img-fluid mb-n4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                      <ExpendAssignUserPackage setUserDataAccount={setUserDataAccount}/>
                        {/* <div className="card">
                            <div className="card-body wizard-content">

                                {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}



                                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">

                                <div className="content clearfix">


                                   

                                 
                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Role Name:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Role Name'
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="name" value={name} onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label htmlFor="wfirstName2">Select Screen:<span className="danger">*</span></label>
                                                <Select
                                                isMulti
                                                menuPortalTarget={document.body} menuPosition={'fixed'}
                                                options={options}
                                                onChange={setSelectedOption}
                                                className="basic-multi-select"
                                                classNamePrefix="select screen"
                                            />
                                            </div>
                                        </div>
                                    </div>


                                    <div class="actions clearfix">
                                        <ul role="menu" aria-label="Pagination">
                                            <li aria-hidden="false" aria-disabled="false">
                                            <button type="submit" onClick={handleSubmit} disabled={isButtonDisabled} class="mr-5 btn btn-primary text-white float-end">
                                               
                                                    {loaderSubmit ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                        </div>
                                                    ) : (
                                                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                    )}
                                                
                                            </button>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}

                    </section>
                </div>

            </Page>
        </div>
    // <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
    // <Sidebar onNavBarClick = {onNavBarClick} />
    // <Page title="Assign Package" onNavBarClick = {onNavBarClick}  flex>
    //   <AssignPackagePageHeader userDataAccount={userDataAccount}/>
    //   <ExpendAssignUserPackage setUserDataAccount={setUserDataAccount}/>
    // </Page>
    // </div>
  );
}

export default AssignUserPackage;
