import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import {
  formatNumber,
  removeTrailingZeros,
  userLoggedAccessToken,
  htmlToPlainText,
  truncateText,
} from "../../../../helpers/helper";
const moment = require("moment");

const ExpandOrderCommissionView = (props) => {
  const orderCommissionData = props?.data;
  const dbDateFormat = "YYYY-MM-DD HH:mm:ssZ";

  const columns = [
    {
      name: "Order ID",
      width: "200px",
      sortable: false,
      cell: (d) => {
        return d?.order_number;
      },
    },

    {
      name: "Order Date",
      sortable: false,
      width: "160px",
      cell: (d) => {
        return moment(d?.purchase_date, dbDateFormat).format("LL");
      },
    },

    // {
    //   name: "Product Name",
    //   sortable: false,
    //   width: "160px",
    //   cell: (d) => {
    //     return d?.name;
    //   },
    // },

    // {
    //   name: "Product SKU",
    //   sortable: false,
    //   width: "150px",
    //   cell: (d) => {
    //     return d?.sku;
    //   },
    // },

    // {
    //   name: "Order Details",
    //   width: "230px",
    //   sortable: false,
    //   cell: (d) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="user-meta-info">
    //           <h6 className="mb-1 fw-semibold chat-title">{d?.order_number}</h6>
    //           <small>
    //             {moment(d?.purchase_date, dbDateFormat).format("LL")}
    //           </small>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Product Details",
      width: "200px",
      sortable: false,
      cell: (d) => {
        return (
          <div className="d-flex align-items-center">
            <div className="user-meta-info">
              <h6 className="mb-1 fw-semibold chat-title" title={htmlToPlainText(d?.name)}>{truncateText(d?.name,30)}</h6>
              <small>SKU: {d?.sku}</small>
              <br />
              <small>VARIANT: {d?.variant}</small>
            </div>
          </div>
        );
      },
    },

    // {
    //   name: "Variant",
    //   sortable: false,
    //   cell: (d) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="user-meta-info">
    //           <h6 className="mb-1 fw-semibold chat-title">{d?.variant_type}</h6>
    //           <small>{d?.variant_value}</small>
    //         </div>
    //       </div>
    //     );
    //   },
    // },

    // {
    //   name: "SKU",
    //   width: "150px",
    //   sortable: false,
    //   cell: (d) => {
    //     // return 1;
    //   },
    // },

    // {
    //   name: "Variant",
    //   width: "130px",
    //   sortable: false,
    //   cell: (d) => {
    //     return d?.variant;
    //   },
    // },
    {
      name: "Quantity",
      width: "100px",
      sortable: false,
      cell: (d) => {
        return 1;
      },
    },

    {
      name: "Order Amount",
      sortable: false,
      width: "150px",
      cell: (d) => {
        return formatNumber(d?.price);
      },
    },

    {
      name: "Commission Amount",
      sortable: false,
      width: "170px",
      cell: (d) => {
        return formatNumber(removeTrailingZeros(d?.order_commission_amount));
      },
    },
  ];

  //   const data = orderCommissionData;

  //   const tableData = {
  //     columns,
  //     orderCommissionData.commissionDetails,
  //   };

  return (
    <div className="table-expend-view">
      <button
        className="close-toggle-btn"
        onClick={(e) => {
          props.closeFromChild();
        }}
        type="button"
      >
        <i className="ti ti-x fs-7"></i>
      </button>

      <div className="">
        {/* {!dataLoader && ( */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="invoice-inner-part">
            <div className="invoiceing-box">
              <div
                className="pt-2"
                id="custom-invoice"
                style={{ display: "block" }}
              >
                <div
                  className="invoice-123 mb-3"
                  id="printableArea"
                  style={{ display: "block" }}
                >
                  <div className="row ">
                    <DataTable
                      columns={columns}
                      data={orderCommissionData?.commission_orders}
                      selectableRows
                      customStyles={{
                        rows: {
                          style: {
                            backgroundColor: "#fafafa", // Set the background color for table rows
                          },
                        },
                        headRow: {
                          style: {
                            backgroundColor: "#fafafa", // Set the background color for table header row
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default ExpandOrderCommissionView;
