import React from "react";
import Helmet from "react-helmet";
import loginSecurity from "../../../assets/vs_char_5.png";
import whiteVideoSquirrelLogo from "../../../assets/Logonew.svg";
import { Link } from "react-router-dom";
function OnboardingPage({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
      <div className="position-relative overflow-hidden radial-gradient min-vh-100">
        <div className="position-relative z-index-5">
          <div className="row">

            <div className="col-xl-7 col-xxl-8 header-logo-div">
              <Link to="/" className="text-nowrap logo-img d-block px-4 py-9 w-100">
                <img src={whiteVideoSquirrelLogo} width="180" alt=""/>
              </Link>
              <div className="d-none d-xl-flex align-items-center justify-content-center">
                <img src={loginSecurity} alt="" className="img-fluid"/>
              </div>
            </div>
            
            <div className="col-xl-5 col-xxl-4">
            {children}
            </div>
              </div>
            </div>                  
      </div>
    </div>
    </>
  );
}

export default OnboardingPage;
