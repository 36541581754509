import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { useNavigate } from 'react-router-dom';
import {useTable, useSortBy,useFlexLayout,useResizeColumns,useExpanded} from "react-table";

const AdminUsersList = () => {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {

    const userscreens = window.localStorage.getItem("admin_screens");
    const screensArray = userscreens.split(',');
    if (!screensArray.includes("admin-users")) {
      navigate("/");
    }

    console.log("lhhkkhkh");


    fetchVideosData();

  }, [currentPage,rowsperPage, searchValue]);

  const fetchVideosData = () => {
    fetch(process.env.REACT_APP_API_URL +'/all-admins', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
     body: JSON.stringify({ "currentPage": currentPage ,"rowsPage": rowsperPage,"search": searchValue}),
    })
      .then((response) => response.json())
      .then((data) => {
        setVideotdata(data.data)
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true)
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  const fetchDeleteData = (id) => {
    // var token = localStorage.getItem("authSession")
    // var token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/delete-admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id}), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        // setVideotdata(data.data)
        if (data.code == "200") {
          setSuccess("user deleted succesfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 1000);
        } else {
          setErrors("user is not deleted")
          setTimeout(resetState, 1000);
        }

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setErrors("Something went wrong")
        setTimeout(resetState, 1000);
    
      });

 
  };

  const handleClick = (id) => {
    //console.log(`You clicked me! ${id}`);
    navigate("/edit-admin-users/"+id)
  };

  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId)
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
};

  const Popup = ({onClose, onDelete }) => {
    return (

      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{display: "block"," padding-left": "0px"}} aria-modal="true" role="dialog">
    
      <div class="modal-dialog modal-sm" >
        <div class="modal-content modal-filled bg-light-danger">
          <div class="modal-body p-4">
            <div class="text-center text-danger">
            <i class="ti ti-x fs-7"  onClick={onClose}></i>
              <h4 class="mt-2">Are You sure?</h4>
              <p class="mt-3">
              You Want to delete Permanently?
              </p>
              <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
              <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>

      
            
            
    );
  };

  const columns = [
    // {
    //   name: "#",
    //   selector: row => row.sno,
    //   sortable: true,
    //   width: '60px',
    // },
    {
      name: "User Name",
      selector: row => row.name,
      sortable: true,
      width: '150px',
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
      width: '200px',
    },
    {
      name: "Role",
      selector: row => row.role_name,
      sortable: true,
      width: '110px',
    },
    {
      name: "Contact Number",
      selector: row => row.phone,
      sortable: true,
      width: '180px',
    },
    {
      name: "Signup Date",
      selector: row => row.created_at,
      sortable: true,
      width: '200px',
    },
    // {
    //   name: "Status",
    //   selector: row => row.status,
    //   sortable: true,
    //   width: '100px',
    //   cell: (d) => (
    //     d.status === '0' ? (
    //       <span className="badge bg-light-danger rounded-3 py-8 text-danger fw-semibold fs-2">Deactive</span>
    //     ) : (
    //       <span className="badge bg-light-success rounded-3 py-8 text-success fw-semibold fs-2">Active</span>
    //     )
    //   ),
    // },
    {
      name: "Action",
      selector: row => row.id,
      sortable: true,
      width: '100px',
      cell: (d) => {
          return <div><i class="ti ti-pencil cursor-pointer" key={d.id}onClick={handleClick.bind(this, d.id)}></i><i class="ti ti-trash cursor-pointer" key={d.id}onClick={handleDeleteClick.bind(this, d.id)}></i></div>
        //return <div><span className="badge bg-light-success rounded-3 py-8 text-success fw-semibold fs-2" key={d.id} onClick={handleClick.bind(this, d.id)} >login</span></div>

        
        }
    }
    
  
  ]



  
  const data = videodata;

  const tableData = {
    columns,
    data
  };

  const handlePageChange = (page) => {
  
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    setCurrentPage(page);
    setrRowsperPage(newPerPage)

  };



  const handleSearchChange = (event) => {

    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false)

  };


  return (
    <>

<div className="card card-body App">

{showPopup ? 
      <Popup
        onClose={handleClosePopup}
        onDelete={handleDelete}
      />:""}
{(errors) ? <div class="alert alert-danger" role="alert"><strong>Error</strong> {(errors)} </div> : ""}
{(success) ? <div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div> : ""}

<div id="complex_header_filter" class="dataTables_filter search_section data-table-extensions-filter">
  <label for="filterDataTable" class="icon "></label>
   
      <input
      type="text"
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      />
      
      </div>

      {loader ?
      
        <DataTableExtensions {...tableData} export={false} print={false} >
          <DataTable
            columns={columns}
            data={data}                                    
            defaultSortField="id"
            sortIcon={<SortIcon />}
            defaultSortAsc={true}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
            dense
          />
        </DataTableExtensions>
        : <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      }
{showPopup ? <div class="modal-backdrop fade show"></div>:""}
   

        </div>

    </>
  );
};

export default AdminUsersList;
