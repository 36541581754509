import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
} from "react-table";
import { Link } from "react-router-dom";
import ExpendAssignUserPackage from "./ExpendAssignUserPackage";
import * as helper from "vs-admin-panel/helper";
const moment = require("moment");

const UserList = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [OTPLoaders, setOTPLoaders] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [expandViewType, setExpandViewType] = useState();
  const [isScheduleDemoColumnWidth, setIsScheduleDemoColumnWidth] =
    useState(100);

  const defaultAvatar =
    "https://s3-us-west-1.amazonaws.com/avatars-zipline-co/default-avatar.png";

  useEffect(() => {
    const userscreens = window.localStorage.getItem("admin_screens");
    const screensArray = userscreens.split(",");
    if (!screensArray.includes("users")) {
      navigate("/");
    }

    fetchVideosData();
  }, [currentPage, rowsperPage, searchValue]);

  const fetchVideosData = () => {
    setIsScheduleDemoColumnWidth(100);
    fetch(process.env.REACT_APP_API_URL + "/all-users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.userLoggedAccessToken(),
      },
      body: JSON.stringify({
        currentPage: currentPage,
        rowsPage: rowsperPage,
        search: searchValue,
      }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        setVideotdata(data.data);
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDeleteData = (id) => {
    var token = localStorage.getItem("authSession");
    var token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/delete-lead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({ id: id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        // setVideotdata(data.data)
        if (data.code == "200") {
          setSuccess("Lead deleted succesfully");
          setSelectedOrderId(null);
          fetchVideosData();
          setLoader(true);
          setTimeout(resetState, 1000);
        } else {
          setErrors("Something went wrong");
          setTimeout(resetState, 1000);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setErrors("Something went wrong");
        setTimeout(resetState, 1000);
      });
  };

  const handleEditClick = (id) => {
    navigate("/edit-user/" + id);
    // window.open(urls, '_blank');
  };

  const handleClick = (id) => {
    // console.log(`You clicked me! ${id}`);
    // history.push("/edit-lead/"+id)
    fetchuserData(id);
  };

  const scheduleDemo = (id) => {
    // alert(id)
    // console.log(`You clicked me! ${id}`);
    navigate("/add-demo/" + id);
  };

  const fetchuserData = (id) => {
    var admin_id = window.localStorage.getItem("admin_id");
    fetch(process.env.REACT_APP_API_URL + "/admin-url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userid: id, adminid: admin_id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == "200") {
          var urls =
            process.env.REACT_APP_MAIN_APP_URL +
            "?code=" +
            data.data +
            "&source=admin";
          window.open(urls, "_blank");
        } else {
          // setErrors("Something went wrong")
          // setTimeout(resetState, 1000);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setErrors("Something went wrong");
        setTimeout(resetState, 1000);
      });
  };

  const resendOTP = (accountData) => {
    const newOption = {
      id: accountData?.id,
    };

    setOTPLoaders((prevOptions) => [...prevOptions, newOption]);

    const fetch_prods = new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + "/resend-otp", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: accountData.email,
          fromAdmin: true,
        }),
      })
        .then((response) => {
          const uploadOTPLoaders = OTPLoaders.filter(
            (OTPLoader) => OTPLoader.id !== accountData?.id
          );
          setOTPLoaders(uploadOTPLoaders);

          if (response.ok) {
            response.json().then((json) => {
              setSuccess(json?.message);
              setTimeout(resetState, 3000);
            });
          } else {
            response.json().then((json) => {
              if (json?.message) {
                // setOTPError(json?.message);
              }
            });
          }
        })
        .catch((error) => {
          const uploadOTPLoaders = OTPLoaders.filter(
            (OTPLoader) => OTPLoader.id !== accountData?.id
          );
          setOTPLoaders(uploadOTPLoaders);

          // delete OTPLoaders[accountData?.id];
          // OTPLoaders[accountData?.id]=false;
          // setOTPLoaders(OTPLoaders);

          // setOTPLoaders(false);
          setErrors("Something went wrong");
          setTimeout(resetState, 3000);
          // setSendOTPLoaders(false);
          // setOTPError('OTP Resend: something went wrong!');
        });
    });
  };

  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId);
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const resetState = () => {
    setErrors("");
    setSuccess("");
  };

  const Popup = ({ onClose, onDelete }) => {
    return (
      <div className="modal-dialog-parent">
        <div class="modal-dialog modal-sm" id="al-danger-alert">
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7"></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">You Want to delete Permanently?</p>
                <button
                  type="button"
                  class="btn btn-light my-2 btn-cnl"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-light my-2 btn-dlt"
                  data-bs-dismiss="modal"
                  onClick={onDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   name: "#",
    //   selector: row => row.sno,
    //   sortable: true,
    //   width: '60px',
    // },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "250px",
      cell: (d) => {
        const pictureToDisplay =
          d?.brand_picture?.length >= 1 && d?.brand_picture != defaultAvatar
            ? d?.brand_picture
            : d?.picture;

        return (
          <div className="d-flex align-items-center">
            <img
              src={pictureToDisplay}
              alt="avatar"
              className="rounded-circle"
              width={35}
              height={35}
            />
            <div className="ms-3">
              <div className="user-meta-info">
                <h6
                  className="mb-1 fw-semibold chat-title"
                  data-name="Amelia Armstrong"
                >
                  {d.name}
                </h6>
                <span
                  className="user-work fs-3"
                  data-occupation="Ethical Hacker"
                >
                  {d.email}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   name: "Email",
    //   selector: row => row.email,
    //   sortable: true,
    //   width: '280px',
    // },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      width: "100px",
    },
    {
      name: "Contact Number",
      selector: (row) => row.contact_number,
      sortable: true,
      width: "170px",
    },
    {
      name: "Signup Date",
      selector: (row) => moment(row.created_at).format("D MMMM YYYY"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Current Plan",
      // selector: (row) => (row.plan_name ? row.plan_name : row.plan_nick_name),

      cell: (d) => {
        return (
          <div className="d-flex align-items-center">
            <div className="">
              <div className="user-meta-info">
                <h6
                  className="mb-1 fw-semibold chat-title"
                  data-name="Amelia Armstrong"
                >
                  {d.plan_nick_name ? d.plan_nick_name : d.plan_name?d.plan_name:"No Active Plan" }
                </h6>
                
                <small>
                  {d.subscribed_plan_status}
                </small>
                {/* <span
                  className="user-work fs-3"
                  data-occupation="Ethical Hacker"
                >
                </span> */}
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "140px",
      cell: (d) => {
        let labelColor;
        labelColor = { color: "info", text: d.status };

        switch (d.status) {
          case "Active":
            labelColor.color = "success";
            break;

          case "Unverified":
            labelColor.color = "info";
            break;

          case "Inactive":
            labelColor.color = "warning";
            break;

          case "Terminated":
            labelColor.color = "danger";
            break;

          default:
            labelColor = { color: "info", text: "Invalid" };
            break;
        }

        const badgeClassName = `rounded-3 py-8 'fw-semibold fs-2 hand-cursor'}`;

        return (
          <div>
            {/* <span  className={badgeClassName} key={d.id} onClick={() => handleEditClick(d.id)}>
            {labelColor.text}
          </span> */}

            <button
              type="submit"
              key={d.id}
              onClick={() => handleEditClick(d.id)}
              class="mr-5 btn btn-primary text-white float-end"
            >
              {labelColor.text}
            </button>
          </div>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => row.id,
      sortable: true,
      width: "340px",
      cell: (d) => {
        const isOTPButtonDisabled = OTPLoaders.find(
          (OTPLoader) => OTPLoader.id === d.id
        );

        const toShowButton =
          moment(d.current_period_end).isAfter(moment()) &&
          d.subscribed_plan_status == "Active";

        return (
          <div>
            {toShowButton === true && (
              <Link
                disabled={d.subscribed_plan_status == "Active"}
                to={`/users/assign-package/${helper.extractNumberFromUserId(
                  d.id
                )}`}
                style={{ textDecoration: "none" }}
                class="mr-5 btn btn-primary text-white float-end"
              >
                Assign Package
              </Link>
            )}

            {toShowButton === false && (
              <button
                type="button"
                disabled={true}
                style={{ textDecoration: "none" }}
                class="mr-5 btn btn-primary text-white float-end"
              >
                Assign Package
              </button>
            )}

            {d.status == "Unverified" ? (
              <button
                type="type"
                key={d.id}
                onClick={(e) => resendOTP(d)}
                disabled={isOTPButtonDisabled}
                class="mr-5 btn btn-primary text-white float-end"
              >
                <div class="d-flex align-items-center">
                  {isOTPButtonDisabled ? (
                    <div>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Sending
                    </div>
                  ) : (
                    <div> Send OTP</div>
                  )}
                </div>
              </button>
            ) : (
              ""
            )}

            <button
              type="submit"
              key={d.id}
              onClick={handleClick.bind(this, d.id)}
              class="mr-5 btn btn-primary text-white float-end"
            >
              Login
            </button>
          </div>
        );
      },
    },
    {
      name: "Schedule Demo",
      selector: (row) => row.id,
      sortable: true,
      width: isScheduleDemoColumnWidth,
      // width: 350,
      // width: '200px',
      cell: (d) => {
        if (d.is_demo == "1") {
          setIsScheduleDemoColumnWidth("200px");
        }
        // isScheduleDemoColumnWidth, setIsScheduleDemoColumnWidth
        return (
          <div>
            {d.is_demo == "1" ? (
              <button
                type="submit"
                key={d.id}
                onClick={scheduleDemo.bind(this, d.id)}
                class="mr-5 btn btn-primary text-white float-end"
              >
                Schedule Demo
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const data = videodata;

  const tableData = {
    columns,
    data,
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setrRowsperPage(newPerPage);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false);
  };

  const clearSearch = () => {
    setSearchValue("");
    fetchVideosData();
  };

  const toggleShareView = (row, expandType) => {
    if (row?.id === currentRow?.id && expandType != expandViewType) {
      setCurrentRow(row);
    } else {
      setCurrentRow(currentRow == row ? null : row);
    }
    setExpandViewType(expandType);
  };

  const closeFromChild = () => {
    setCurrentRow(null);
  };

  return (
    <>
      <div className="card card-body App">
        {showPopup ? (
          <Popup onClose={handleClosePopup} onDelete={handleDelete} />
        ) : (
          ""
        )}

        {errors ? (
          <div class="alert alert-danger" role="alert">
            <strong>Error</strong> {errors}{" "}
          </div>
        ) : (
          ""
        )}
        {success ? (
          <div class="alert alert-success" role="alert">
            <strong>Success</strong> {success}{" "}
          </div>
        ) : (
          ""
        )}

        <div
          id="complex_header_filter"
          class="dataTables_filter search_section data-table-extensions-filter"
        >
          <label for="filterDataTable" class="icon "></label>

          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search"
          />
        </div>

        {loader ? (
          <div>
            <DataTableExtensions {...tableData} export={false} print={false}>
              <DataTable
                columns={columns}
                data={data}
                sortIcon={<SortIcon />}
                className="hide-expandable-icons-row"
                defaultSortField="id"
                defaultSortAsc={true}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                dense
                expandableRows
                expandableRowsComponent={(props) =>
                  expandViewType === 1 ? (
                    <ExpendAssignUserPackage
                      setParentSuccess={setSuccess}
                      setParentErrors={setErrors}
                      {...props}
                      closeFromChild={closeFromChild}
                    />
                  ) : (
                    ""
                  )
                }
                expandableRowExpanded={(row) => row === currentRow}
              />
            </DataTableExtensions>
          </div>
        ) : (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserList;
